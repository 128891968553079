import React from 'react'
import moment from 'moment'
import './claim-select.css'

const ClaimSelect = ({
  selected,
  selectRef,
  onHandleChangeSelect,
  types,
  flag
}) => {
  const options = () => {
    if (flag !== 'current') {
      return types.map(item => {
        return (
          <option value={item.desc_type_claim} key={item.desc_type_claim}>
            {item.desc_type_claim}
          </option>
        )
      })
    }
    return types.map(item => {
      return (
        <option value={item.id_claim} key={item.id_claim}>
          Заявка №{item.id_claim} {item.desc_type_claim}{' '}
          {moment(item.date_create).format('DD.MM.YYYY, H:mm:ss')}
        </option>
      )
    })
  }

  const text = flag !== 'current' ? 'Оберіть тип звернення' : 'Оберіть заявку'
  return (
    <div className='claim-select-wrapper'>
      <div className='text-claim-select'>{text}</div>
      <div className='claim-inputs'>
        <div className='input-field col s12 select'>
          <select
            value={selected}
            ref={selectRef}
            onChange={onHandleChangeSelect}
            className='claim-select'
          >
            <option value='' disabled>
              {text}
            </option>
            {options()}
          </select>
        </div>
      </div>
    </div>
  )
}

export default ClaimSelect
