import React, { Component } from "react";
import "./emails-list.css";
import { Button, Icon } from "semantic-ui-react";

class EmailsList extends Component {
  render() {
    const { emails, deleteEmail } = this.props;
    return (
      <ul className="emails-list">
        {emails &&
          emails.map((item, idx) => (
            <List key={idx} idx={idx} item={item} deleteEmail={deleteEmail} />
          ))}
      </ul>
    );
  }
}

class List extends Component {
  state = {
    isLoadingBTN: false
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleDeleteEmail = async item => {
    const { deleteEmail } = this.props;
    this.setState({ isLoadingBTN: true });
    await deleteEmail(item);
    if (this._isMounted) {
      this.setState({ isLoadingBTN: true });
    }
  };

  render() {
    const { item, idx } = this.props;
    const { isLoadingBTN } = this.state;
    return (
      <li className="email-list-item">
        <span className="emails-list-text">
          {idx + 1}) {item}
        </span>
        <div className="email-list-button-container">
          <React.Fragment>
            <Button
              onClick={() => this.handleDeleteEmail(item)}
              className="delete-email"
              primary
              content="Видалити"
              icon="trash"
              disabled={isLoadingBTN}
              loading={isLoadingBTN}
            />
            <Icon
              className="icon-delete-email"
              name="trash"
              loading={isLoadingBTN}
              disabled={isLoadingBTN}
              onClick={() => this.handleDeleteEmail(item)}
            />
          </React.Fragment>
        </div>
      </li>
    );
  }
}

export default EmailsList;
