const initialState = {
  phoneNumber: "",
  isRoot: null,
  password: "",
  confirmPassword: ""
};

const registration = (state = initialState, { type, payload }) => {
  switch (type) {
    case "PHONE_NUMBER":
      return {
        ...state,
        phoneNumber: payload
      };
    case "IS_AUTH":
      return {
        ...state,
        isRoot: payload
      };

    case "PASSWORD_CHANGE":
      return {
        ...state,
        password: payload
      };

    case "CHECK_PASSWORD_CHANGE":
      return {
        ...state,
        confirmPassword: payload
      };

    default:
      return state;
  }
};

export default registration;
