import React, { Component } from 'react';
import SupportActions from '../../components/support-actions/support-actions';

class SupportPage extends Component {
    state = {  }
    render() { 
        const {history} = this.props
        return ( 
            <SupportActions history={history}/>
         );
    }
}
 
export default SupportPage;