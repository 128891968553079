import React, { Component } from "react";
import "./password-card.css";

import { changePassword, changeCheckPassword } from "../../actions";
import { connect } from "react-redux";
import ModalButtons from "../modal-buttons/modal-buttons";

class PasswordCard extends Component {
  onhandleChangePassword = e => {
    const { changePassword } = this.props;
    const { value } = e.target;
    changePassword(value);
  };

  onhandleChangeConfirmPassword = e => {
    const { value } = e.target;
    const { changeConfirmPassword } = this.props;
    changeConfirmPassword(value);
  };

  isDisabled = () => {
    const { dbUser, password, confirmPassword } = this.props;
    if (!dbUser) {
      return password !== confirmPassword || password.length < 6;
    }
    return password.length < 6;
  };

  render() {
    const {
      modal,
      dbUser,
      closeModal,
      password,
      confirmPassword,
      onHandleSubmitPassword,
      isDisabled,
      failedPassword,
      forgotPassword,
      isForgotPassword,
      isSettings,
      isEditPassword,
      focusInputPassword
    } = this.props;
    const textForClient = () => {
      if (!dbUser && !isForgotPassword) {
        return "Це ваш перший вхід в систему. Щоб продовжити реєстрацію Вам необхідно придумати пароль (не менше 6 символів).";
      }
      if (dbUser && !isEditPassword) {
        return "Щоб продовжити, введіть пароль.";
      }
      if (dbUser && isEditPassword) {
        return 'Введіть старий пароль та натисніть "Надіслати".';
      }
      if (!dbUser && isForgotPassword) {
        return "Введіть новий пароль та підтвердіть його.";
      }
    };

    const confirm = !dbUser && (
      <div className="input-field">
        <input
          onChange={this.onhandleChangeConfirmPassword}
          id="confirm"
          type="password"
          className="validate"
          value={confirmPassword}
          placeholder="Підтвердити пароль"
        />
      </div>
    );

    const className = dbUser && "once";

    return (
      <div className={`modal password-block ${className}`} ref={modal}>
        <React.Fragment>
          <div
            className="modal-content password-modal"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              height: "auto"
            }}
          >
            <h4>Шановний клієнте!</h4>
            <p className="password-p">{textForClient()} </p>
          </div>
          <form className="modal-footer" style={{ height: "auto" }}>
            <div className="input-field">
              <input
                onChange={this.onhandleChangePassword}
                id="password"
                type="password"
                className="validate"
                value={password}
                ref={focusInputPassword}
                placeholder="Введіть пароль"
              />

              {failedPassword && (
                <span
                  className="helper-text red-text"
                  style={{ width: "100%", textAlign: "left" }}
                >
                  {failedPassword}
                </span>
              )}
              {dbUser && !isSettings && (
                <span
                  style={{ width: "100%", textAlign: "left" }}
                  onClick={forgotPassword}
                  className="helper-text blue-text forgot"
                >
                  Забули пароль?
                </span>
              )}
            </div>
            {confirm}
            <ModalButtons
              isDisabled={isDisabled}
              disabled={this.isDisabled()}
              handleCancel={closeModal}
              handleSubmit={onHandleSubmitPassword}
            />
          </form>
        </React.Fragment>

        <button
          onClick={closeModal}
          className="btn waves-effect waves-light modal-close-mobile"
        >
          <i className="material-icons center">close</i>
        </button>
      </div>
    );
  }
}

const mapStateToProps = ({ registration }) => ({
  confirmPassword: registration.confirmPassword,
  password: registration.password
});
const mapDispatchToProps = dispatch => ({
  changePassword: pass => dispatch(changePassword(pass)),
  changeConfirmPassword: pass => dispatch(changeCheckPassword(pass))
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordCard);
