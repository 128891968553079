import React from "react";
import "./current-clayms-messages-actions.css";
import ButtonPreloader from "../button-preloader/button-preloader";

const ClaymsMessagesActions = ({
  onHandleClickNewMessage,
  onHandleClickWithoutRating,
  onHandleClickWithRating,
  isLoading,
  onlineStatus,
  returnToRevision
}) => {
  if (isLoading) {
    return (
      <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <ButtonPreloader />
      </div>
    );
  }
  return (
    <div className="clayms-messages-actions-wrapper">
      <button
        onClick={onHandleClickWithRating}
        className="btn clayms-messages-actions"
      >
        закрити
      </button>
      <button
        onClick={onHandleClickWithoutRating}
        className="btn clayms-messages-actions"
      >
        закрити без оцінки
      </button>
      {onlineStatus !== 2 ? (
        <button
          className="btn clayms-messages-actions"
          onClick={onHandleClickNewMessage}
        >
          нове повідомлення
        </button>
      ) : (
        <button
          className="btn clayms-messages-actions"
          onClick={returnToRevision}
        >
          на доопрацювання
        </button>
      )}
    </div>
  );
};

export default ClaymsMessagesActions;
