import React, { Component } from "react";
import "./contracts-card.css";
import PdfAction from "../pdf-action/pdf-action";
import { connect } from "react-redux";
import SoneApiService from "../../services/c1-api-services/c1-api-service";
import ModalTypeInfo from "../modal-type-info/modal-type-info";
import MaterializeService from "../../services/materialize-sevices/materialize-js";
import { isAuth } from "../../actions";
import SettingsService from "../../services/settings-services/settingsService";
import { domain } from "../../config/config";
import { styleColor, textColor, summDebt, textDebt } from "./helpers";
import ObjectsModal from "../objects-modal/objects-modal";
import { Button } from "semantic-ui-react";
import { setPayment } from "../../actions/payment";
import axios from "axios";
import AppealsModal from "../appeals-modal/appeals-modal";

const materialize = new MaterializeService();
const apiservice = new SoneApiService();
const settingService = new SettingsService();
const { getObjectsApi } = apiservice;
const { initModal, toastSend } = materialize;
const { postNewSettings, fetchContractSetting } = settingService;

const baseURL = `${domain}/yavir2000/file/`;

class ContractsCard extends Component {
  state = {
    isLoading: false,
    isModalLoading: false,
    isModalBtnLoading: false,
    billMedok: false,
    billEmail: false,
    debtTelegram: false,
    isLoadingAppeals: false,
    appeals: [],
    isAppealWindowOpened: false,
  };

  typeInfo = React.createRef();
  modalObjectRef = React.createRef();
  modalPrivat = React.createRef();

  componentDidMount() {
    this.typeInfoInstanse = initModal(this.typeInfo.current);
    this.modalObjectRefInstanse = initModal(this.modalObjectRef.current);
    this.modalPrivatRefInstanse = initModal(this.modalPrivat.current);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.contractsList !== this.props.contractsList) {
      this.currentEmail = this.props.billEmail;
      this.currentTelegram = this.props.debtTelegram;
      this.currentMedok = this.props.billMedok;
    }
    if (prevProps.currentContragent !== this.props.currentContragent) {
      this.setState({
        showLiqpay: false,
      });
    }
  }

  closeTypeInfo = () => {
    this.typeInfoInstanse.close();
  };

  backFromLiqpay = () => {
    this.setState({ showLiqpay: false });
  };

  onhandleSubmitContractsSettings = async (refKey) => {
    const { isRoot, isAuth } = this.props;
    const { billEmail, billMedok, debtTelegram } = this.state;
    try {
      this.setState({ isModalLoading: true });
      const { error } = await postNewSettings(isRoot, {
        medoc: billMedok,
        telegramm: debtTelegram,
        email: billEmail,
        refKey,
      });
      if (error) {
        return toastSend("Щось пішло не так, спробуйте ще раз.");
      }
      toastSend("Дані збережено!");
      this.setState({ isModalLoading: false });
      this.closeTypeInfo();
    } catch (error) {
      localStorage.clear();
      isAuth(null);
      console.log(error);
    }
  };

  onHandleGetObjects = async (item, e) => {
    const { isRoot, getObjects, isAuth, history } = this.props;
    try {
      this.setState({ isLoading: true });
      const { Result } = await getObjectsApi({ Ref_Key: item }, isRoot);
      getObjects(Result);
      this.setState({ isLoading: false });
      this.modalObjectRefInstanse.open();
    } catch (error) {
      isAuth(null);
      localStorage.clear();
      history.push("/login");
    }
  };

  closeObjectModal = () => {
    this.modalObjectRefInstanse.close();
  };

  onHandleClickObjectSettings = async () => {
    const { isRoot, Ref_Key, isAuth, history } = this.props;
    try {
      this.setState({ isModalBtnLoading: true });
      const { billEmail, billMedok, debtTelegram } = await fetchContractSetting(isRoot, {
        refKey: Ref_Key,
      });
      this.setState({
        isModalBtnLoading: false,
        debtTelegram,
        billEmail,
        billMedok,
      });
      this.typeInfoInstanse.open();
    } catch (error) {
      isAuth(null);
      localStorage.clear();
      history.push("/login");
    }
  };

  onHandleGetPdf = async (route, Ref_Key) => {
    const { isRoot } = this.props;
    const token = isRoot.split(" ")[1];
    const url = `${baseURL}${route}/${Ref_Key}?&&param=${token}`;
    window.open(url);
  };

  isShowLiqPay = (isliqpay) => {
    if (isliqpay) {
      return this.setState({
        showLiqpay: true,
      });
    }
    toastSend("Для даної угоди поки що немає можливості здійснювати проплату");
  };

  openPaymentsModal = () => {
    if (this.props.isliqpay) {
      this.modalPrivatRefInstanse.open();
    } else {
      toastSend("Для даної угоди поки що немає можливості здійснювати проплату");
    }
  };

  handleChangeContragentSettings = (type) => {
    switch (type) {
      case "telegram":
        this.setState({ debtTelegram: !this.state.debtTelegram });
        break;
      case "medok":
        this.setState({ billMedok: !this.state.billMedok });
        break;
      case "email":
        this.setState({ billEmail: !this.state.billEmail });
        break;
      default:
        break;
    }
  };

  handleGetAppeals = async () => {
    this.setState({ isLoadingAppeals: true });

    try {
      const { data } = await axios.get(
        `/api/applications/getCabContactsApp?q=${this.props.number}`
      );
      this.setState({
        appeals: data.data,
        isAppealWindowOpened: true,
        isLoadingAppeals: false,
      });
    } catch (err) {
      console.error(err);
      this.setState({ isLoadingAppeals: false });
    }
  };

  render() {
    const {
      number,
      isRoot,
      summa,
      history,
      Ref_Key,
      contractsList,
      objectsList,
      isAuth,
      phoneNumber,
      isliqpay,
      Organization_Ref_Key,
      iban,
      edrpou,
      orgName,
    } = this.props;

    const {
      billEmail,
      billMedok,
      debtTelegram,
      isModalLoading,
      isModalBtnLoading,
      isLoading,
      isLoadingAppeals,
      isAppealWindowOpened,
      appeals,
    } = this.state;

    const showButton = () => {
      if (!isliqpay) return;
      return (
        <div className="payments-action">
          <Button
            className="btn cost privat24"
            onClick={() => {
              this.props.setPayment({
                orgName,
                edrpou,
                iban,
                refKey: Ref_Key,
                contractNumber: number,
                isliqpay,
                currentContragent: this.props.currentContragent,
                token: isRoot,
                history,
                isAuth,
                phonenumber: phoneNumber,
                Organization_Ref_Key,
                textDebt: textDebt(summa),
                summDebt: summDebt(summa),
                summaOneEs: summa,
              });
              history.push("/payments");
            }}
            content="сплатити"
            color="green"
            icon="payment"
          />
        </div>
      );
    };

    return (
      <React.Fragment>
        {contractsList && (
          <div className="card-wrapper-contracts">
            <div className="contracts-content">
              <h6 className="contract-number">{number}</h6>
              <div className="objects-action">
                <div className="pdf-buttons">
                  <PdfAction getPdf={this.onHandleGetPdf} Ref_Key={Ref_Key} />
                </div>
                <Button
                  className="objects-settings-btn"
                  onClick={this.onHandleClickObjectSettings}
                  content="налаштування"
                  loading={isModalBtnLoading}
                  primary
                  disabled={isModalBtnLoading}
                  icon="setting"
                />
                <div className="objects-settings-row">
                  <Button
                    onClick={(e) => this.onHandleGetObjects(Ref_Key, e)}
                    className="objects-settings-btn"
                    content="Об'єкти"
                    primary
                    loading={isLoading}
                    disabled={isLoading}
                    icon="building"
                  />
                  <Button
                    onClick={this.handleGetAppeals}
                    className="objects-settings-btn"
                    content="Заявки"
                    primary
                    loading={isLoadingAppeals}
                    disabled={isLoadingAppeals}
                    icon="clipboard list"
                  />
                </div>
              </div>
            </div>
            <div className="contracts-content">
              <div className="debt-content" style={{ background: styleColor(summa) }}>
                <h6 className="text-debt" style={{ color: textColor(summa) }}>
                  {textDebt(summa).text}:
                </h6>
                {textDebt(summa).description && (
                  <React.Fragment>
                    <h6 className="text-debt description" style={{ color: textColor(summa) }}>
                      {textDebt(summa).description}:
                    </h6>
                  </React.Fragment>
                )}
                <h6 className="summ-debt" style={{ color: textColor(summa) }}>
                  {summDebt(summa)}
                </h6>
              </div>
              {showButton()}
            </div>
            <ModalTypeInfo
              refKey={Ref_Key}
              modalRef={this.typeInfo}
              billEmail={billEmail}
              billMedok={billMedok}
              debtTelegram={debtTelegram}
              closeTypeInfo={this.closeTypeInfo}
              onSubmitContractsSettings={this.onhandleSubmitContractsSettings}
              isModalLoading={isModalLoading}
              history={history}
              handleChangeContragentSettings={this.handleChangeContragentSettings}
            />
            <AppealsModal
              contractNumber={number}
              appeals={appeals}
              isOpened={isAppealWindowOpened}
              handleClose={() => this.setState({ isAppealWindowOpened: false })}
            />
            <ObjectsModal
              list={objectsList}
              closeObjectModal={this.closeObjectModal}
              modalObject={this.modalObjectRef}
              contractNumber={number}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ contragents, registration }) => ({
  isRoot: registration.isRoot,
  phoneNumber: registration.phoneNumber,
  contractsList: contragents.contractsList,
  currentContragent: contragents.currentContragent,
});

const mapDispatchToProps = (dispatch) => ({
  isAuth: (bool) => dispatch(isAuth(bool)),
  setPayment: (payment) => dispatch(setPayment(payment)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContractsCard);
