import axios from 'axios'

export default class SettingsService {
  fetchSettings = async (token, body) => {
    const { data } = await axios.post('/yavir2000/api/settings', body, {
      headers: {
        Authorization: token
      }
    })
    return data
  }

  fetchContractSetting = async (token, body) => {
    const { data } = await axios.post('/yavir2000/api/settings/contractsetting', body, {
      headers: {
        Authorization: token
      }
    })
    return data
  }

  postNewSettings = async (token, body) => {
    const { data } = await axios.put('/yavir2000/api/settings', body, {
      headers: {
        Authorization: token
      }
    })
    return data
  }

  deleteEmaill = async (token, body) => {
    const { data } = await axios.patch('/yavir2000/api/settings', body, {
      headers: {
        Authorization: token
      }
    })
    return data
  }

  deleteTelegramAkkApi = async (token) => {
    const { data } = await axios.get('/yavir2000/api/settings', {
      headers: {
        Authorization: token
      }
    })
    return data
  }
}
