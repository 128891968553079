import React, { useState } from "react";
import { Accordion, Icon, Table, Modal, Button, Loader } from "semantic-ui-react";
import "./objects-list.css";
import axios from "axios";
import { useSelector } from "react-redux";

const ObjectsList = ({ list, contractNumber }) => {
  const currentContractor = useSelector((state) => state.contragents.currentContragent);

  const [activeIndex, setActiveIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [responsiblePersons, setResponsiblePersons] = useState({});
  const [pultInfo, setPultInfo] = useState({});
  const [inspectorRefkey, setInspectorRefkey] = useState("");
  const [pultResponsiblePersons, setPultResponsiblePersons] = useState({});
  const [currentDelete, setCurrentDelete] = useState(null);
  const [initialResponsibles, setInitialResponsibles] = useState({});

  const inspectorNumbers = (inspectorPhones) => {
    return inspectorPhones.map((phone, idx) => (
      <React.Fragment key={idx}>
        {idx + 1 === inspectorPhones.length ? (
          <span>{phone.Представление}</span>
        ) : (
          <span>{`${phone.Представление}, `}</span>
        )}
      </React.Fragment>
    ));
  };

  const handleAccordionClick = (index, refKey) => {
    setActiveIndex(activeIndex === index ? null : index);

    if (!responsiblePersons[refKey]) {
      fetchResponsiblePersons(refKey);
    }
  };

  const fetchResponsiblePersons = async (refKey) => {
    setLoading(true);
    try {
      const { data } = await axios.get(`/api/objects/objectDetailNew/${refKey}`);

      const contacts = data.response.contacts;
      const pultInfoData = data.response.pultInfo;
      const mainInfo = data.response.mainInfo;

      const pultInfoArr = pultInfoData.map((item) => {
        return {
          prefix: item.Префикс,
          object_number: item.НомерКонцентратора,
        };
      });

      const uniquePultInfo = [
        ...new Map(pultInfoArr.map((item) => [item.prefix + item.object_number, item])).values(),
      ];

      setInspectorRefkey((prev) => ({
        ...prev,
        [refKey]: mainInfo.Инспектор_Ref_Key,
      }));

      setPultInfo((prev) => ({
        ...prev,
        [refKey]: uniquePultInfo,
      }));

      setResponsiblePersons((prev) => ({
        ...prev,
        [refKey]: contacts,
      }));

      setInitialResponsibles((prev) => ({
        ...prev,
        [refKey]: [...contacts],
      }));

      await sendPultInfo(refKey, uniquePultInfo);
    } catch (err) {
      console.error("Error fetching responsible persons:", err);
    } finally {
      setLoading(false);
    }
  };

  const sendPultInfo = async (refKey, pultData) => {
    try {
      const body = pultData.map((item) => ({
        prefix: item.prefix,
        object_number: item.object_number,
      }));

      const { data } = await axios.post(`/protect/cms/contacts`, body);

      setPultResponsiblePersons((prev) => ({
        ...prev,
        [refKey]: data.response,
      }));
    } catch (error) {
      console.error(`Помилка при відправці пультової інформації`, error);
    }
  };

  const handleInputChange = (e, refKey, index, field) => {
    const updatedData = [...responsiblePersons[refKey]];
    updatedData[index][field] = e.target.value;
    setResponsiblePersons({
      ...responsiblePersons,
      [refKey]: updatedData,
    });
  };

  const handleAddRow = (refKey) => {
    const newRow = {
      Фамилия: "",
      Имя: "",
      Отчество: "",
      Телефон: "",
    };
    setResponsiblePersons((prev) => ({
      ...prev,
      [refKey]: [...(prev[refKey] || []), newRow],
    }));
  };

  const handleCreateAppeal = async (refkey) => {
    const object = list.find((i) => i.Ref_Key === refkey);

    const pultData =
      pultInfo[refkey]?.find((item) => item.prefix === "AJX") || pultInfo[refkey]?.[0];

    const body = {
      object_Ref_Key: refkey,
      object_name: object.name,
      object_address: object.address,
      object_inspektor_Ref_Key: inspectorRefkey[refkey],
      object_pult_name: pultData.prefix,
      object_pult_number: pultData.object_number,
      number_contract: contractNumber,
      contragent_name: currentContractor.fullname,
      source: 2,
      status: 1,
      Ref_Key_create: currentContractor.Ref_Key,
      fio_create: currentContractor.fullname,
    };

    try {
      await axios.post(`/api/applications/createContactsApplication`, body);
      alert("Заявка на зміну успішно створена");
    } catch (err) {
      console.error(err);
    }
  };

  const handleEditResponsibles = async (refkey) => {
    const allFieldsFilled = responsiblePersons[refkey].every(
      (contact) => contact.Фамилия && contact.Имя && contact.Отчество && contact.Телефон
    );

    if (!allFieldsFilled) {
      alert("Будь ласка, заповніть всі поля перед відправкою.");
      return;
    }

    const initialResponsiblesList = initialResponsibles[refkey] || [];
    const isLengthChanged = responsiblePersons[refkey].length !== initialResponsiblesList.length;

    const hasChanges =
      isLengthChanged ||
      responsiblePersons[refkey].some((contact, index) => {
        const initialContact = initialResponsiblesList[index];
        return (
          !initialContact ||
          contact.Фамилия !== initialContact.Фамилия ||
          contact.Имя !== initialContact.Имя ||
          contact.Отчество !== initialContact.Отчество ||
          contact.Телефон !== initialContact.Телефон
        );
      });

    if (!hasChanges) {
      return;
    }

    const body = {
      objectRefKey: refkey,
      contacts: responsiblePersons[refkey],
    };

    try {
      await axios.post(`/api/applications/postContacts1C`, body).then(async () => {
        await handleCreateAppeal(refkey);
      });
    } catch (err) {
      console.error(err);
      alert("Виникла помилка при відправці");
    }
  };

  const isRowEmpty = (contact) => {
    return !contact.Ref_Key;
  };

  const handleOpenDeleteModal = (refKey, index, contactRefKey, phone) => {
    const updatedData = [...responsiblePersons[refKey]];

    if (isRowEmpty(updatedData[index])) {
      updatedData.splice(index, 1);
      setResponsiblePersons({
        ...responsiblePersons,
        [refKey]: updatedData,
      });
    } else {
      setCurrentDelete({ refKey, phone, contactRefKey });
      setDeleteModalOpen(true);
    }
  };

  const handleDelete = async () => {
    const { refKey, contactRefKey, phone } = currentDelete;

    const currentContact = responsiblePersons[refKey].find(
      (i) => i.Ref_Key === contactRefKey && i.Телефон === phone
    );

    const body = {
      objectKey: refKey,
      contact: {
        Ref_Key: currentContact.Ref_Key,
        ВидKey: currentContact.ВидKey,
      },
    };

    setResponsiblePersons((prev) => ({
      ...prev,
      [refKey]: prev[refKey]?.filter((i) => !(i.Ref_Key === contactRefKey && i.Телефон === phone)),
    }));

    try {
      await axios.post(`/api/applications/deleteContactPerson`, body);
    } catch (err) {
      console.error(err);
      alert("Виникла помилка при видаленні");
    } finally {
      setDeleteModalOpen(false);
      setCurrentDelete(null);
    }
  };

  return (
    <Accordion styled fluid className="object-list">
      {list &&
        list.map((item, idx) => {
          const { name, address, inspectorName, inspectorPhones, Ref_Key } = item;
          return (
            <React.Fragment key={idx}>
              <Accordion.Title
                active={activeIndex === idx}
                index={idx}
                onClick={() => handleAccordionClick(idx, Ref_Key)}
                className="accordion-title"
              >
                <div className="title-content">
                  <Icon name="dropdown" />
                  <span>{name}</span>
                </div>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === idx}>
                <p>
                  <b>Адреса:</b> {address}
                </p>
                {inspectorName ? (
                  <>
                    <p>
                      <b>Інспектор:</b> {inspectorName}
                    </p>
                    <p>
                      <b>Контактний телефон:</b>&nbsp;
                      {inspectorPhones.length ? inspectorNumbers(inspectorPhones) : "+380970022000"}
                    </p>
                  </>
                ) : (
                  <p>
                    <b>Контактний телефон:</b> +380970022000
                  </p>
                )}
                {loading ? (
                  <Loader active inline="centered" style={{ borderColor: "#13F195" }} />
                ) : (
                  <div className="object-list__responsible">
                    <div className="object-list__responsible__wrapper">
                      <h3>Довірені особи</h3>
                      <div className="object-list__responsible__content">
                        <div className="object-list__responsible__content__oneC">
                          <h4>Облікова система</h4>
                          <div className="table-wrapper">
                            <Table celled>
                              <Table.Header>
                                <Table.Row>
                                  <Table.HeaderCell>Прізвище</Table.HeaderCell>
                                  <Table.HeaderCell>Ім'я</Table.HeaderCell>
                                  <Table.HeaderCell>По-батькові</Table.HeaderCell>
                                  <Table.HeaderCell>Номер телефону</Table.HeaderCell>
                                  <Table.HeaderCell>Опції</Table.HeaderCell>
                                </Table.Row>
                              </Table.Header>
                              <Table.Body>
                                {responsiblePersons[Ref_Key] &&
                                responsiblePersons[Ref_Key].length > 0 ? (
                                  responsiblePersons[Ref_Key].map((contact, idx) => (
                                    <Table.Row key={idx}>
                                      <Table.Cell>
                                        <input
                                          type="text"
                                          value={contact.Фамилия}
                                          placeholder="Прізвище"
                                          onChange={(e) =>
                                            handleInputChange(e, Ref_Key, idx, "Фамилия")
                                          }
                                        />
                                      </Table.Cell>
                                      <Table.Cell>
                                        <input
                                          type="text"
                                          value={contact.Имя}
                                          placeholder="Ім'я"
                                          onChange={(e) =>
                                            handleInputChange(e, Ref_Key, idx, "Имя")
                                          }
                                        />
                                      </Table.Cell>
                                      <Table.Cell>
                                        <input
                                          type="text"
                                          value={contact.Отчество}
                                          placeholder="По батькові"
                                          onChange={(e) =>
                                            handleInputChange(e, Ref_Key, idx, "Отчество")
                                          }
                                        />
                                      </Table.Cell>
                                      <Table.Cell>
                                        <input
                                          type="text"
                                          value={contact.Телефон}
                                          placeholder="Телефон"
                                          onChange={(e) =>
                                            handleInputChange(e, Ref_Key, idx, "Телефон")
                                          }
                                        />
                                      </Table.Cell>
                                      <Table.Cell>
                                        <button
                                          onClick={() =>
                                            handleOpenDeleteModal(
                                              Ref_Key,
                                              idx,
                                              contact.Ref_Key,
                                              contact.Телефон
                                            )
                                          }
                                        >
                                          <Icon name="trash" />
                                        </button>
                                      </Table.Cell>
                                    </Table.Row>
                                  ))
                                ) : (
                                  <Table.Row>
                                    <Table.Cell colSpan="4">Дані відсутні</Table.Cell>
                                  </Table.Row>
                                )}
                              </Table.Body>
                            </Table>
                          </div>
                          {responsiblePersons[Ref_Key]?.length > 0 && (
                            <div className="object-list__responsible__content__buttons">
                              <Button onClick={() => handleAddRow(Ref_Key)} color="blue">
                                Додати
                              </Button>
                              <Button onClick={() => handleEditResponsibles(Ref_Key)} color="green">
                                Зберегти
                              </Button>
                            </div>
                          )}
                        </div>
                        <div className="object-list__responsible__content__pult">
                          <h4>Пультова інформація</h4>
                          <div className="table-wrapper">
                            <Table celled>
                              <Table.Header>
                                <Table.Row>
                                  <Table.HeaderCell>ПІБ</Table.HeaderCell>
                                  <Table.HeaderCell>Телефон</Table.HeaderCell>
                                </Table.Row>
                              </Table.Header>

                              <Table.Body>
                                {pultResponsiblePersons[Ref_Key] &&
                                pultResponsiblePersons[Ref_Key].length > 0 ? (
                                  pultResponsiblePersons[Ref_Key].map((contact, idx) => (
                                    <Table.Row key={idx}>
                                      <Table.Cell>{contact.fio}</Table.Cell>
                                      <Table.Cell>{contact.phone_num}</Table.Cell>
                                    </Table.Row>
                                  ))
                                ) : (
                                  <Table.Row>
                                    <Table.Cell colSpan="2">Дані відсутні</Table.Cell>
                                  </Table.Row>
                                )}
                              </Table.Body>
                            </Table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Accordion.Content>
              <Modal
                className="responsible-confirm-modal"
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                size="small"
              >
                <Modal.Header>Підтвердіть видалення</Modal.Header>
                <Modal.Content>
                  <p>Ви впевнені, що хочете видалити довірену особу?</p>
                </Modal.Content>
                <Modal.Actions>
                  <Button onClick={() => setDeleteModalOpen(false)}>Відмінити</Button>
                  <Button negative onClick={() => handleDelete()}>
                    Видалити
                  </Button>
                </Modal.Actions>
              </Modal>
            </React.Fragment>
          );
        })}
    </Accordion>
  );
};

export default ObjectsList;
