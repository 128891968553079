import React from "react";
import "./style.css";

const ButtonSubmit = ({ disabled, handleSubmit, text }) => {
  return (
    <button className="btn submit" onClick={handleSubmit} disabled={!disabled}>
      {text}
    </button>
  );
};

export default ButtonSubmit;
