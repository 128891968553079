import React, { Component } from "react";
import "./footer.css";
import { domain } from "../../config/config";
import AboutUs from "../../components/about-us-modal/about-us";
import MaterializeService from "../../services/materialize-sevices/materialize-js";
import { getLiqpayOrganizations } from "../../services/liqpay-api-service/ligpay-api";

const mastercard = require("../../assets/mastercard.png");
const visa = require("../../assets/visa.png");
const privat24 = require("../../assets/privat24_1.png");

const { initModal } = new MaterializeService();

class Footer extends Component {
  modalAboutUs = React.createRef();

  state = {
    organizations: []
  };

  fetchOrganizations = async () => {
    const { organizations } = await getLiqpayOrganizations();
    this.setState({ organizations });
  };
  componentDidMount = () => {
    this.modalAboutUsinitialize = initModal(this.modalAboutUs.current);
  };
  getPdf = async () => {
    window.open(`${domain}/yavir2000/file/agreement`);
  };

  openModal = async () => {
    await this.fetchOrganizations();
    this.modalAboutUsinitialize.open();
  };

  closeModal = () => {
    this.modalAboutUsinitialize.close();
  };
  render() {
    return (
      <div className="footer-wrapper">
        <div className="footer-content">
          <div className="footer-links">
            <div className="links-text" onClick={this.getPdf}>
              Угода користувача
            </div>
            <div className="links-text" onClick={this.openModal}>
              Про нас
            </div>
          </div>
          <div className="payment-methods">
            <div className="payment-methods-content">
              <div className="payment-methods-text">Способи оплати:</div>
              <div className="images-content">
                <img
                  className="image-footer"
                  src={mastercard}
                  alt="mastercard"
                />
                <img className="image-footer" src={visa} alt="mastercard" />
                <img
                  className="image-footer privat24"
                  src={privat24}
                  alt="mastercard"
                />
              </div>
            </div>
          </div>
        </div>
        <AboutUs
          modalAboutUs={this.modalAboutUs}
          closeModal={this.closeModal}
          organizations={this.state.organizations}
        />
      </div>
    );
  }
}

export default Footer;
