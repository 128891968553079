import axios from 'axios'

export default class SupportService {
  submitClaimApi = async (body, token) => {
    const { data } = await axios.post('/yavir2000/api/support', body, {
      headers: {
        Authorization: token
      }
    })
    return data
  }

  submitNewClaimApi = async (body, token) => {
    const { data } = await axios.post('/yavir2000/api/support/new', body, {
      headers: {
        Authorization: token
      }
    })
    return data
  }

  closeClaimWithoutRating = async (body, token) => {
    const { data } = await axios.post(
      '/yavir2000/api/support/close_without_rating',
      body,
      {
        headers: {
          Authorization: token
        }
      }
    )
    return data
  }

  getCurrentClaimApi = async (body, token) => {
    const { data } = await axios.post(
      '/yavir2000/api/support/current_claim',
      body,
      {
        headers: {
          Authorization: token
        }
      }
    )
    return data
  }

  getCurrentMessagesApi = async (body, token) => {
    const { data } = await axios.post(
      '/yavir2000/api/support/current_messages',
      body,
      {
        headers: {
          Authorization: token
        }
      }
    )
    return data
  }

  fetchClaymType = async token => {
    const { data } = await axios.get('/yavir2000/api/support/types', {
      headers: {
        Authorization: token
      }
    })
    return data
  }
}
