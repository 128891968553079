import React, { Component } from "react";
import "./settings-text.css";
import { connect } from "react-redux";
import { fetchSettingsSuccess, isAuth } from "../../actions";
import SettingsButton from "../settings-button/settings-button";
import EmailsList from "../emails-list/emails-list";
import ModalDeleteTelegram from "../modal-delete-telegram/modal-delete-telegram";
import MaterializeService from "../../services/materialize-sevices/materialize-js";
import SettingsService from "../../services/settings-services/settingsService";
import { deleteTelegramAkk } from "../../actions/settings-action";
import { Icon } from "semantic-ui-react";

const settingApi = new SettingsService();
const { deleteTelegramAkkApi } = settingApi;

const materialize = new MaterializeService();
const { initModal } = materialize;

class SettingsText extends Component {
  state = {
    isLoading: false
  };

  billsDropDown = React.createRef();
  tooltip = React.createRef();
  modaldeleteTelegram = React.createRef();

  componentDidMount() {
    this.deleteTelegramInstanse = initModal(this.modaldeleteTelegram.current);
  }

  openModalDeleteTelegram = () => {
    this.deleteTelegramInstanse.open();
  };

  closeModalDeleteTelegram = () => {
    this.deleteTelegramInstanse.close();
  };

  handleSubmitDeleteTelegram = async () => {
    const { isRoot, isAuth, history, deleteTelegramAction } = this.props;
    this.setState({ isLoading: true });
    const { error } = await deleteTelegramAkkApi(isRoot);
    if (error) {
      this.setState({ isLoading: false });
      this.deleteTelegramInstanse.close();
      isAuth(null);
      localStorage.clear();
      history.push("/login");
      return;
    }
    this.setState({ isLoading: false });
    deleteTelegramAction();
    this.deleteTelegramInstanse.close();
  };

  render() {
    const {
      emails,
      phonenumber,
      onHandleClickChangePassword,
      onHandleClickChangeEmail,
      deleteEmail,
      isLoadingDelete,
      isTelegram
    } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="settings-text-wrapper">
        <div className="column-wrapper">
          <div className="settings-text">
            <Icon name="phone" size="small" style={{ marginRight: "10px" }} />
            <b style={{ marginRight: "5px" }}>Телефон: </b>
            <span>{phonenumber}</span>
          </div>
        </div>
        <div className="column-wrapper">
          <div className="settings-text">
            <Icon name="key" size="small" style={{ marginRight: "10px" }} />
            <b style={{ marginRight: "5px" }}>Пароль:</b> **********
          </div>
          <div className="settings-button-box">
            <SettingsButton
              text={"Змінити"}
              className={"settings-button"}
              iconName={"edit"}
              onHandleClick={onHandleClickChangePassword}
            />
          </div>
        </div>
        <div className="column-wrapper">
          <div className="settings-text">
            <Icon name="mail" size="small" style={{ marginRight: "10px" }} />
            <b style={{ marginRight: "5px" }}>Email: </b>
            {emails && emails.length === 0 && (
              <span className="no-email">Ви ще не додали email</span>
            )}
          </div>
          <div className="settings-button-box">
            <SettingsButton
              text={"Додати"}
              className={"settings-button"}
              iconName={"add"}
              onHandleClick={onHandleClickChangeEmail}
            />
          </div>
        </div>
        {emails && emails.length !== 0 && (
          <div className="column-wrapper settings-text" style={{ margin: "0" }}>
            <EmailsList
              emails={emails}
              deleteEmail={deleteEmail}
              isLoadingDelete={isLoadingDelete}
            />
          </div>
        )}
        {isTelegram && (
          <div className="column-wrapper">
            <div className="settings-text">
              <Icon
                name="telegram plane"
                size="small"
                style={{ marginRight: "10px" }}
              />
              <b style={{ marginRight: "5px" }}>Телеграм: </b>
            </div>
            <div className="settings-button-box">
              <SettingsButton
                text={"Видалити"}
                className={"settings-button"}
                iconName={"trash"}
                onHandleClick={this.openModalDeleteTelegram}
              />{" "}
            </div>
          </div>
        )}
        <ModalDeleteTelegram
          modalDelegramDelete={this.modaldeleteTelegram}
          closeModal={this.closeModalDeleteTelegram}
          submitDelete={this.handleSubmitDeleteTelegram}
          isLoading={isLoading}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ settings, registration, contragents }) => ({
  isRoot: registration.isRoot,
  phonenumber: settings.phonenumber,
  isTelegram: settings.isTelegramAkk,
  currentContragent: contragents.currentContragent
});
const mapDispatchToProps = dispatch => ({
  getSettings: settings => dispatch(fetchSettingsSuccess(settings)),
  isAuth: bool => dispatch(isAuth(bool)),
  deleteTelegramAction: () => dispatch(deleteTelegramAkk())
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsText);
