import React from "react";
import { Segment, Button } from "semantic-ui-react";

import "./modal-buttons.css";

const ModalButtons = ({
  handleSubmit,
  handleCancel,
  disabled = false,
  isDisabled,
  isOnlyClose = false,
  isSettings = false
}) => {
  return (
    <Segment
      className="modal-action modals"
      disabled={isDisabled}
      loading={isDisabled}
    >
      {!isOnlyClose && (
        <Button
          onClick={handleSubmit}
          className="modals-button"
          type="submit"
          primary
          disabled={disabled || isDisabled}
          loading={isDisabled}
          content={isSettings ? "Зберегти" : "Надіслати"}
          icon={isSettings ? "save" : "send"}
        />
      )}
      <Button
        onClick={handleCancel}
        className={`modals-button ${!isOnlyClose ? "mobile" : "mobile-once"}`}
        content="Закрити"
        icon="cancel"
        color="red"
      />
    </Segment>
  );
};

export default ModalButtons;
