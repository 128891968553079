import React from 'react'
import './header-preloader.css'

const HeaderPreloader = () => {
  return (
    <div className='progress' style={{ position: 'absolute' }}>
      <div className='indeterminate' />
    </div>
  )
}

export default HeaderPreloader
