import React from "react";
import "./archive-page-content.css";
import { Menu } from "semantic-ui-react";

const ArchibePageContent = props => {
  return (
    <div className="archives-actions-buttons" style={{ padding: "0 5px" }}>
      <Menu
        inverted
        widths={2}
        className="archives-nav-item"
        style={{ background: "#7594ab" }}
      >
        <Menu.Item
          name="Платежі"
          icon="money bill alternate"
          className="archive-nav"
          active={props.history.location.pathname === "/archives/bills"}
          onClick={() => props.history.push("/archives/bills")}
        />
        <Menu.Item
          name="Акти та рахунки"
          icon="list"
          className="archive-nav"
          active={props.history.location.pathname === "/archives/acts"}
          onClick={() => props.history.push("/archives/acts")}
        />
      </Menu>
    </div>
  );
};

export default ArchibePageContent;
