import React, { Component } from "react";
import { Route } from "react-router-dom";
import MaterializeService from "../../services/materialize-sevices/materialize-js";
import CabinetHeader from "../../components/cabinet-header/cabinet-header";
import { connect } from "react-redux";
import Contracts from "../contracts/contracts";
import {
  isAuth,
  selectContragent,
  getContractsSuccess,
  selectContracts,
  getObjectsSuccess,
  getContragentsSuccess,
  showContragentTitle,
  showContractTitle,
  fetchSettingsSuccess
} from "../../actions";
import CheckTokenService from "../../services/check-token-service/check-token-service";
import ArchivesPage from "../archives-page/archives-page";
import Settings from "../settings/settings";

import "./cabinet-page.css";
import SupportPage from "../support-page/support-page";
import NewClaim from "../new-claim/new-claim";
import Claims from "../claims/claims";
import ClaimsMessages from "../claims-messages/claims-messages";
import SettingsService from "../../services/settings-services/settingsService";
import ArchibePageContent from "../../components/achive-page-content/archive-page-content";
import ArchivesBillsPage from "../archive-bills-page/archive-bills";
import Footer from "../footer/footer";
import PaymentPage from "../payment-page/payment-page";

const settingsService = new SettingsService();
const { fetchSettings } = settingsService;

const materialize = new MaterializeService();
const tokenService = new CheckTokenService();

const { tabsInit, toastSend } = materialize;
const { refreshToken } = tokenService;

class CabinetPage extends Component {
  tabsInit = React.createRef();
  isMounting = false;
  componentDidMount = () => {
    const {
      location: { pathname },
      history: { push }
    } = this.props;
    this.isMounting = true;
    tabsInit(this.tabsInit.current);
    this.setTimeoutForRefresh();
    if (pathname === "/") {
      push("/contracts");
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.currentContragent !== this.props.currentContragent) {
      this.getSettings();
    }
  }

  getSettings = async () => {
    const { isRoot, getSettings, history, currentContragent } = this.props;
    try {
      const response = await fetchSettings(isRoot, { currentContragent });
      const { error } = response;
      if (error) {
        toastSend("Щось пішло не так. Спробуйте ще раз.");
        return;
      }
      getSettings(response);
    } catch (error) {
      this.props.isAuth(null);
      localStorage.clear();
      history.push("/login");
    }
  };

  logOut = () => {
    const {
      isAuth,
      history,
      getContracts,
      getContragents,
      selectContract,
      selectContragent,
      getObjects
    } = this.props;
    localStorage.clear();
    isAuth(null);
    getContracts(null);
    getContragents([]);
    selectContract(null);
    selectContragent(null);
    getObjects(null);
    history.push("/login");
  };

  refreshToken = () => {
    const { isAuth } = this.props;
    this.interval = setInterval(async () => {
      const token = localStorage.getItem("auth");
      const { newToken, newRefreshToken } = await refreshToken(token);
      if (newToken) {
        isAuth(newToken);
        localStorage.setItem("auth", newToken);
        localStorage.setItem("rfr", newRefreshToken);
      } else this.logOut();
    }, 59000 * 5);
  };

  setTimeoutForRefresh = async () => {
    const { isAuth, isRoot } = this.props;
    const token = isRoot;
    const { newToken, newRefreshToken } = await refreshToken(token);
    if (newToken) {
      isAuth(newToken);
      localStorage.setItem("auth", newToken);
      localStorage.setItem("rfr", newRefreshToken);
      this.refreshToken();
    } else {
      this.logOut();
    }
  };

  componentWillUnmount = () => {
    this.isMounting = false;
    clearInterval(this.interval);
  };

  render() {
    const { isShowSettings, socket, appWrapper } = this.props;
    return (
      <React.Fragment>
        <div className="cabinet-wrapper">
          <Route
            path="/"
            render={({ history, location }) => (
              <CabinetHeader
                tabsInit={this.tabsInit}
                logOut={this.logOut}
                history={history}
                location={location}
                onLinkClick={this.onLinkClick}
                isShowSettings={isShowSettings}
              />
            )}
          />
          <Route
            path="/contracts"
            render={({ history }) => (
              <Contracts appWrapper={appWrapper} history={history} />
            )}
          />
          <Route path="/archives" component={ArchibePageContent} />
          <Route path="/payments" component={PaymentPage} />
          <Route path="/archives/acts" component={ArchivesPage} />
          <Route
            path="/archives/bills"
            render={({ history }) => <ArchivesBillsPage history={history} />}
          />
          <Route
            path="/settings"
            render={({ history }) => (
              <Settings socket={socket} history={history} />
            )}
          />
          <Route
            path="/support"
            render={({ history }) => <SupportPage history={history} />}
          />
          <Route
            path="/support/current"
            render={({ history, match }) => (
              <Claims
                history={history}
                match={match}
                type={"current"}
                socket={socket}
              />
            )}
          />
          <Route
            path="/support/current/:id"
            render={({ match, history }) => (
              <ClaimsMessages
                history={history}
                match={match}
                appWrapper={appWrapper}
                type={"current"}
                socket={socket}
              />
            )}
          />
          <Route
            path="/support/archive"
            render={({ history, match }) => (
              <Claims history={history} match={match} type={"archive"} />
            )}
          />
          <Route
            path="/support/archive/:id"
            render={({ match, history }) => (
              <ClaimsMessages
                history={history}
                match={match}
                appWrapper={appWrapper}
                type={"archive"}
              />
            )}
          />
          <Route
            path="/support/new"
            render={({ history }) => <NewClaim history={history} />}
          />
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ registration, contragents }) => ({
  isRoot: registration.isRoot,
  contragentsList: contragents.contragentsList,
  phonenumber: registration.phoneNumber,
  currentContragent: contragents.currentContragent
});

const mapDispatchToProps = dispatch => ({
  isAuth: bool => dispatch(isAuth(bool)),
  selectContragent: item => dispatch(selectContragent(item)),
  getContracts: list => dispatch(getContractsSuccess(list)),
  selectContract: item => dispatch(selectContracts(item)),
  getObjects: list => dispatch(getObjectsSuccess(list)),
  getContragents: list => dispatch(getContragentsSuccess(list)),
  showContragentTitle: text => dispatch(showContragentTitle(text)),
  showContractTitle: text => dispatch(showContractTitle(text)),
  getSettings: settings => dispatch(fetchSettingsSuccess(settings))
});

export default connect(mapStateToProps, mapDispatchToProps)(CabinetPage);
