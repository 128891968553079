import { combineReducers } from "redux";
import registration from "./registration/registration";
import contragents from "./contragents/contragents";
import contractsTitle from "./contracts-title/contracts-title";
import date from "./date/date";
import settings from "./settings/settings";
import modalsInstances from "./modals/modals";
import textAreaText from "./claim/text-area";
import payment from "./payment/payment";

const rootReducer = combineReducers({
  registration,
  contragents,
  contractsTitle,
  date,
  settings,
  modalsInstances,
  textAreaText,
  payment
});

export default rootReducer;
