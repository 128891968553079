import React from "react";
import "./pdf-action.css";
import { Button } from "semantic-ui-react";

const PdfAction = ({ getPdf, Ref_Key }) => {
  return (
    <React.Fragment>
      <Button
        className="btn pdf-button"
        onClick={() => getPdf("getbill", Ref_Key)}
        content="Рахунок"
        primary
        icon="file pdf"
      />
      <Button
        className="btn pdf-button"
        onClick={() => getPdf("getact", Ref_Key)}
        content="Акт"
        primary
        icon="file pdf"
      />
    </React.Fragment>
  );
};

export default PdfAction;
