import React, { Component } from "react";
import RegistrationPage from "./containers/registration-page/registration-page";
import io from "socket.io-client";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import CabinetPage from "./containers/cabinet-page/cabinet-page";

import "./app.css";
import CheckTokenService from "./services/check-token-service/check-token-service";
import { isAuth, getContragentsSuccess, getPhoneNumber } from "./actions";
import ScrollTop from "./components/button-up/button-up";
import MaterializeService from "./services/materialize-sevices/materialize-js";
import { addEmail } from "./actions/contragents-action";
import ErrorPage from "./containers/error-page/error-page";
import { domain } from "./config/config";
import InitPage from "./components/init-page/InitPage";

const materialize = new MaterializeService();

const { toastSend } = materialize;

const checkTokenService = new CheckTokenService();

const { checkToken } = checkTokenService;

const baseURL = domain;

class App extends Component {
  socket = io(baseURL);
  state = {
    isLoading: false,
    scrollTop: 0,
    isError: false
  };
  wrapperRef = React.createRef();

  initTimeout = res => {
    this.loadTimeout = setTimeout(res, 3000);
  };

  sleep = () => {
    return new Promise(res => this.initTimeout(res));
  };

  checkToken = async () => {
    const { isAuth, getContragents, history, getPhone } = this.props;
    this.setState({ isLoading: true });
    const token = localStorage.getItem("rfr");
    try {
      if (token) {
        const response = await checkToken(token);
        const { Result, phone } = response;
        if (response) {
          isAuth(token);
          getContragents(Result);
          getPhone(phone);
        } else {
          localStorage.clear();
          isAuth(null);
          history.push("/login");
        }
      } else {
        localStorage.clear();
        isAuth(null);
        history.push("/login");
      }
    } catch (error) {
      localStorage.clear();
      isAuth(null);
      history.push("/login");
    } finally {
      await this.sleep();
      this.setState({ isLoading: false });
    }
  };

  componentDidUpdate = prevProps => {
    if (prevProps.emailModalInstanse !== this.props.emailModalInstanse) {
      this.emailInst = this.props.emailModalInstanse;
    }

    if (prevProps.phonenumber === null && this.props.phonenumber !== null) {
      const { addEmailAction, phonenumber } = this.props;
      this.socket.on("verifyEmail", data => {
        if (data.phonenumber === phonenumber) {
          addEmailAction(data);
          if (this.emailInst) this.emailInst.close();
          toastSend("Пошта додана!");
        }
      });
    }
  };

  componentDidMount = () => {
    this.checkToken();
    this.wrapperRef.current.onscroll = () => {
      this.setState({
        scrollTop: this.wrapperRef.current.scrollTop
      });
    };
  };

  componentWillUnmount = () => {
    clearTimeout(this.loadTimeout);
  };

  onhandleClickUp = () => {
    this.wrapperRef.current.scrollTop = 0;
  };

  componentDidCatch() {
    this.setState({ isError: true });
  }

  render() {
    const { isRoot } = this.props;
    const { scrollTop, isError, isLoading } = this.state;
    
    if (isError) {
      return <ErrorPage />;
    }
    
    if (isLoading) {
      return <InitPage />;
    }

    return (
      <div className="app" ref={this.wrapperRef}>
        <Router>
          <Switch>
            <Route
              path="/"
              render={props => {
                if (isRoot) {
                  return (
                    <CabinetPage
                      {...props}
                      socket={this.socket}
                      appWrapper={this.wrapperRef.current}
                    />
                  );
                }
                if (!isRoot) return <RegistrationPage {...props} />;
              }}
            />
          </Switch>
        </Router>
        {scrollTop > 100 && (
          <ScrollTop onhandleClickUp={this.onhandleClickUp} />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ registration, modalsInstances, settings }) => ({
  isRoot: registration.isRoot,
  emailModalInstanse: modalsInstances.emailModalInstanse,
  phonenumber: settings.phonenumber,
  id_setting: settings.id_setting
});

const mapDispatchToProps = dispatch => ({
  isAuth: bool => dispatch(isAuth(bool)),
  getContragents: list => dispatch(getContragentsSuccess(list)),
  getPhone: phone => dispatch(getPhoneNumber(phone)),
  addEmailAction: data => dispatch(addEmail(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
