import M from "materialize-css";

class MaterializeService {
  initModal = element => {
    return M.Modal.init(element);
  };

  initCollapsible = element => {
    return M.Collapsible.init(element);
  };

  tabsInit = element => {
    return M.Tabs.init(element);
  };

  toastSend = (message, duration) => {
    return M.toast({
      html: message,
      classes: "rounded",
      displayLength: !duration ? 4000 : duration
    });
  };

  updateTextField = () => {
    return M.updateTextFields();
  };

  initTooltip = element => {
    return M.Tooltip.init(element);
  };

  initDropdownTrigger = element => {
    return M.Dropdown.init(element);
  };

  floatingBtn = element => {
    return M.FloatingActionButton.init(element, {
      toolbarEnabled: true
    });
  };

  selectInit = element => {
    return M.FormSelect.init(element);
  };

  autoResizeTextArea = element => {
    return M.textareaAutoResize(element);
  };

  initSideNav = element => {
    return M.Sidenav.init(element, { edge: "right" });
  };

  initDatePicker = element => {
    return M.Datepicker.init(element, {
      showClearBtn: true
    });
  };
}

export default MaterializeService;
