export const styleColor = (summa) => {
  const arrSumma = summa.split("");
  if (arrSumma[0] === "-" || summa === "Відсутня") {
    return "#B6F691";
  }
  return "#FFD9C5";
};

export const textColor = (summa) => {
  const arrSumma = summa.split("");
  if (arrSumma[0] === "-" || summa === "Відсутня") {
    return "#59A02F";
  }
  return "#A65831";
};

export const textDebt = (summa) => {
  const arrSumma = summa.split("");
  if (arrSumma[0] === "-") {
    return {
      text: "Переплата",
      description: null
    };
  }
  if (arrSumma.join("") === "Відсутня") {
    return {
      text: "Заборгованість",
      description: null
    };
  }
  return {
    text: "Заборгованість",
    description: "(з урахуванням абонплати за поточний місяць)"
  };
};

export const summDebt = (summa) => {
  const arrSumma = summa.split("");
  if (summa === "Відсутня") return "відсутня";
  const filterSumm = arrSumma.filter(item => item !== "-").join("");
  return `${filterSumm} грн.`;
};
