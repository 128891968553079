import React, { Component } from 'react'
import './archives-page.css'

import { connect } from 'react-redux'
import ArchivesCard from '../../components/archives-card/archives-card'
import { selectContragent } from '../../actions'

class ArchivesPage extends Component {
  render () {
    const { contractsList, isRoot } = this.props
    return (
      <div className='archives-wrapper'>
        {contractsList &&
          contractsList.map((item, idx) => {
            return <ArchivesCard item={item} key={idx} isRoot={isRoot} />
          })}
      </div>
    )
  }
}

const mapStateToProps = ({ contragents, registration }) => ({
  contractsList: contragents.contractsList,
  isRoot: registration.isRoot
})

const mapDispatchToProps = dispatch => ({
  selectContragent: item => dispatch(selectContragent(item))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArchivesPage)
