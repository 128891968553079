import React from 'react'
import StarRatingComponent from 'react-star-rating-component'

import './modal-rating.css'
import Preloader from '../preloader/preloader'

const ModalRating = ({
  modalRating,
  rating,
  onStarClick,
  submitWithRating,
  isLoading,
  closeModal
}) => {
  return (
    <div className='modal modal-rating' ref={modalRating}>
      {isLoading ? (
        <Preloader />
      ) : (
        <React.Fragment>
          <div className='modal-content modal-rating'>
            <h4>Оцініть якість обслуговування </h4>
            <StarRatingComponent
              name='rate1'
              starCount={5}
              value={rating}
              onStarClick={onStarClick}
            />
          </div>
          <div className='modal-footer modal-rating'>
            <div className='modal-action modal-rating'>
              <button
                className='btn waves-effect waves-light'
                onClick={submitWithRating}
              >
                надіслати
              </button>
              <button className='btn waves-effect waves-light red darken-1 modal-close'>
                Закрити
              </button>
              <button
                onClick={closeModal}
                className='btn waves-effect waves-light modal-close-mobile'
              >
                <i className='material-icons center'>close</i>
              </button>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

export default ModalRating
