import React, { Component } from "react";
import { connect } from "react-redux";
import "./cabinet-header.css";
import ContractsCollapsible from "../contracts-collapsible/contracts-collapsible";
import MaterializeService from "../../services/materialize-sevices/materialize-js";
import {
  selectContragent,
  getContractsSuccess,
  getObjectsSuccess,
  isAuth
} from "../../actions";
import SoneApiService from "../../services/c1-api-services/c1-api-service";
import HeaderNavigation from "../header-navigation/header-navigation";
import SideNav from "../sidenav/sidenav";

const materialize = new MaterializeService();
const apiservice = new SoneApiService();

const { initCollapsible, initSideNav } = materialize;
const { getContractsApi } = apiservice;

const yavirLogo = require("../../assets/logo.png");

class CabinetHeader extends Component {
  state = {
    isLoading: false
  };
  count = 0;

  contragentsCollaps = React.createRef();
  sidenavRef = React.createRef();

  componentDidMount = () => {
    this.contragentsCollapsInstanse = initCollapsible(
      this.contragentsCollaps.current
    );
    this.sidenavInstanse = initSideNav(this.sidenavRef.current);

    if (this.props.contragentsList.length) {
      this.firstLoad();
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.isLoading !== this.state.isLoading) {
      this.contragentsCollapsInstanse = initCollapsible(
        this.contragentsCollaps.current
      );
    }
    if (
      this.props.contragentsList.length &&
      !prevProps.contragentsList.length
    ) {
      this.firstLoad();
    }
  };

  firstLoad = async () => {
    const {
      isRoot,
      contragentsList,
      getContracts,
      getObjects,
      selectContragent
    } = this.props;
    this.setState({ isLoading: true });
    getObjects(null);
    const Result = await getContractsApi(
      { Ref_Key: contragentsList[0].Ref_Key },
      isRoot
    );
    selectContragent(contragentsList[0]);
    this.setState({ isLoading: false });
    getContracts(Result);
    this.count++;
  };

  onHandleSelectContragent = async item => {
    const {
      isRoot,
      selectContragent,
      getContracts,
      getObjects,
      isAuth,
      history
    } = this.props;
    try {
      this.setState({ isLoading: true });
      getObjects(null);
      this.contragentsCollapsInstanse.close();
      const Result = await getContractsApi({ Ref_Key: item.Ref_Key }, isRoot);
      selectContragent(item);
      this.setState({ isLoading: false });
      getContracts(Result);
    } catch (error) {
      isAuth(null);
      localStorage.clear();
      history.push("/login");
    }
  };

  openSidenav = () => {
    this.sidenavInstanse.open();
  };

  closeSidenav = () => {
    this.sidenavInstanse.close();
  };

  render() {
    const { contragentsList, currentContragent, history, logOut } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="header-wrapper">
        <div className="header-content">
          <div className="logo-wrapper">
            <img
              className="logo"
              src={yavirLogo}
              alt="logo"
              onClick={() => window.open("https://yavir2000.com")}
            />
          </div>
          <div className="header-center">
            <div className="header-title">Особистий кабінет</div>
            <div className="collaps-content">
              <ContractsCollapsible
                refCollaps={this.contragentsCollaps}
                current={currentContragent}
                list={contragentsList}
                onHandleSelectContragent={this.onHandleSelectContragent}
                isLoading={isLoading}
              />
            </div>
          </div>
          <button className="btn mobile-sidenav" onClick={this.openSidenav}>
            <i className="material-icons right">dehaze</i>
          </button>
          <SideNav
            sidenavRef={this.sidenavRef}
            history={history}
            logOut={logOut}
            closeSidenav={this.closeSidenav}
          />
        </div>
        <HeaderNavigation history={history} logOut={logOut} />
      </div>
    );
  }
}

const mapStateToProps = ({ contragents, registration }) => ({
  contragentsList: contragents.contragentsList,
  isRoot: registration.isRoot,
  currentContragent: contragents.currentContragent
});
const mapDispatchToProps = dispatch => ({
  selectContragent: item => dispatch(selectContragent(item)),
  getContracts: list => dispatch(getContractsSuccess(list)),
  getObjects: list => dispatch(getObjectsSuccess(list)),
  isAuth: bool => dispatch(isAuth(bool))
});

export default connect( 
  mapStateToProps,
  mapDispatchToProps
)(CabinetHeader);
