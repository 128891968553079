import axios from "axios";
import { useState, useEffect } from "react";
import { isDesktop } from "react-device-detect";
import "./qr-content.css";

const QRContent = ({
  iban,
  edrpou,
  contractNumber,
  orgName,
  refKey,
  currentContragent,
  amount,
}) => {
  const [QR, setQR] = useState(null);
  const [QRLink, setQRLink] = useState("");

  useEffect(() => {
    const generateQR = async () => {
      try {
        const { data } = await axios.get(
          `yavir2000/api/qr?iban=${iban}&amount=${amount}&orgName=${orgName}&edrpou=${edrpou}&refKey=${refKey}&contractNumber=${contractNumber}&currentContragent=${currentContragent.fullname}`
        );
        setQR(data.qrCode);
        setQRLink(data.url);
      } catch (error) {
        console.error("Помилка генерації QR:", error);
      }
    };

    generateQR();
  }, [iban, edrpou, contractNumber, orgName, refKey, currentContragent]);

  if (iban === "немає рахунку") {
    return <div>Для даної угоди поки що немає можливості здійснювати оплату</div>;
  }

  return (
    <div
      className="qr-content"
    >
      {QR ? (
        isDesktop ? (
          <>
            <img
              src={QR}
              alt="QR"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
            <p>Відскануйте QR код для оплати</p>
          </>
        ) : (
          <>
            <a href={QRLink} target="_blank">
              <img
                src={QR}
                alt="QR"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </a>
            <p>Натисніть по QR коду для оплати</p>
          </>
        )
      ) : (
        <p>Сталася помилка при отриманні QR коду</p>
      )}
    </div>
  );
};

export default QRContent;
