import React from "react";
import "./header-navigation.css";
import { Menu } from "semantic-ui-react";

const HeaderNavigation = ({ history, logOut }) => {
  return (
    <div className="header-navigation-wrapper">
      <div className="header-navigation">
        {/* <button
          onClick={() => history.push("/contracts")}
          className="btn navigation"
        >
          Угоди
        </button>
        <button
          onClick={() => history.push("/archives")}
          className="btn navigation"
        >
          Архіви
        </button>
        <button
          onClick={() => history.push("/settings")}
          className="btn navigation"
        >
          Налаштування
        </button>
        <button onClick={logOut} className="btn navigation">
          Вийти
        </button> */}
        <Menu className="item-navigation links" inverted widths={4}>
          <Menu.Item
            name="Угоди"
            icon="clipboard list"
            active={history.location.pathname === "/contracts"}
            onClick={() => history.push("/contracts")}
          />
          <Menu.Item
            name="Архіви"
            icon="archive"
            active={history.location.pathname.startsWith("/archives")}
            onClick={() => history.push("/archives/bills")}
          />
          <Menu.Item
            name="Налаштування"
            icon="setting"
            active={history.location.pathname === "/settings"}
            onClick={() => history.push("/settings")}
          />
          <Menu.Item icon="sign out alternate" name="Вийти" onClick={logOut} />
        </Menu>
      </div>
    </div>
  );
};

export default HeaderNavigation;
