import { Modal, Button, Table, Icon, Loader } from "semantic-ui-react";
import { useEffect, useState } from "react";
import axios from "axios";

//styles
import "./appeal-info-modal.css";

const AppealInfoModal = ({
  appealNumber,
  appealRefkey,
  objectRefkey,
  status,
  isOpened,
  handleClose,
}) => {
  const [appeal, setAppeal] = useState(null);
  const [loading, setLoading] = useState(true);

  
  useEffect(() => {
    if (isOpened) {
      setLoading(true);
      (async () => {
        try {
          if (status === 3) {
            const { data } = await axios.get(
              `/api/applications/getContactsAppArchive/${appealRefkey}`
            );

            const { contacts, pult } = data.response;

            const formattedContacts = contacts.map((i) => ({
              Ref_Key: i.personKey,
              Фамилия: i.surname,
              Имя: i.name,
              Отчество: i.patronymic,
              Телефон: i.phone,
              Адрес: i.address,
            }));

            const formattedPult = pult.map((i) => ({
              fio: i.fio,
              phone_num: i.phone,
            }));

            setAppeal({ contacts: formattedContacts, pult: formattedPult });
          } else {
            const { data: oneCData } = await axios.get(
              `/api/objects/objectDetailNew/${objectRefkey}`
            );
            if (!oneCData || !oneCData.response) {
              alert("Виникла помилка при отриманні заявки");
              return;
            }

            const { contacts, pultInfo } = oneCData.response;
            const pultInfoArr = pultInfo.map((item) => ({
              prefix: item.Префикс,
              object_number: item.НомерКонцентратора,
            }));

            const uniquePultInfo = [
              ...new Map(
                pultInfoArr.map((item) => [item.prefix + item.object_number, item])
              ).values(),
            ];

            const { data: pultData } = await axios.post(`/protect/cms/contacts`, uniquePultInfo);
            setAppeal({ contacts, pult: pultData?.response });
          }
        } catch (error) {
          alert("Помилка при отриманні даних");
        } finally {
          setLoading(false);
        }
      })();
    }

    return () => {
      if (!isOpened) {
        setAppeal(null);
      }
    };
  }, [isOpened]);

  console.log(appeal);

  return (
    <Modal open={isOpened} onClose={handleClose} className="appeal-modal">
      <Modal.Header className="appeal-modal-header">
        Заявка №{appealNumber}
        <Icon name="close" onClick={handleClose} className="appeal-modal__close-icon" />
      </Modal.Header>
      <Modal.Content className="appeal-modal-content">
        {loading ? (
          <Loader
            active
            inline="centered"
            style={{ zIndex: 10000, borderColor: "black", "&:after": { borderColor: "none" } }}
          />
        ) : (
          <div className="appeal-modal__responsible">
            <div className="appeal-modal__responsible__wrapper">
              <div className="appeal-modal__responsible__content">
                <div className="appeal-modal__responsible__content__oneC">
                  <h4>Облікова система</h4>
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Прізвище</Table.HeaderCell>
                        <Table.HeaderCell>Ім'я</Table.HeaderCell>
                        <Table.HeaderCell>По-батькові</Table.HeaderCell>
                        <Table.HeaderCell>Номер телефону</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {appeal &&
                        appeal.contacts.map((contact, idx) => (
                          <Table.Row key={idx}>
                            <Table.Cell>{contact.Фамилия}</Table.Cell>
                            <Table.Cell>{contact.Имя}</Table.Cell>
                            <Table.Cell>{contact.Отчество}</Table.Cell>
                            <Table.Cell>{contact.Телефон}</Table.Cell>
                          </Table.Row>
                        ))}
                    </Table.Body>
                  </Table>
                </div>
                <div className="appeal-modal__responsible__content__pult">
                  <h4>Пультова інформація</h4>
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>ПІБ</Table.HeaderCell>
                        <Table.HeaderCell>Телефон</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {appeal &&
                        appeal.pult.map((contact, idx) => (
                          <Table.Row key={idx}>
                            <Table.Cell>{contact.fio}</Table.Cell>
                            <Table.Cell>{contact.phone_num}</Table.Cell>
                          </Table.Row>
                        ))}
                    </Table.Body>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal.Content>
      <Modal.Actions className="appeal-modal-actions">
        <Button onClick={handleClose} color="red">
          Закрити
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AppealInfoModal;
