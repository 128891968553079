import React from "react";
import "./settings-button.css";
import { Button, Icon } from "semantic-ui-react";

const SettingsButton = ({ onHandleClick, text, iconName }) => {
  return (
    <React.Fragment>
      <Button
        content={text}
        onClick={onHandleClick}
        className={`settings-button`}
        icon={iconName}
        primary
      />
      <Icon
        className="settings-button-mobile"
        name={iconName}
        onClick={onHandleClick}
      />
    </React.Fragment>
  );
};

export default SettingsButton;
