import React from "react";
import "./style.css";

const ModalCloseButton = () => {
  return (
    <button className="modal-close waves-effect waves-green btn-flat close-inspectors">
      <i className="material-icons">close</i>
    </button>
  );
};

export default ModalCloseButton;
