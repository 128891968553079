import React from "react";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/dist/style.css";
import "./registration-card.css";
import { Button } from "semantic-ui-react";

const RegistrationCard = ({
  phoneNumber,
  onHandlerChangeInput,
  onHandleClick,
  isDisabled
}) => {
  return (
    <form className="card">
      <div className="card-content">
        <h1 className="card-title">
          Для входу в систему введіть свій номер телефону
        </h1>
        <div className="input-field">
          <ReactPhoneInput
            className="phone-input"
            onChange={onHandlerChangeInput}
            value={phoneNumber}
            defaultCountry={"ua"}
            onlyCountries={["ua"]}
          />
        </div>
      </div>
      <div className="card-action" style={{ width: "100%" }}>
        <Button
          onClick={onHandleClick}
          primary
          className="submit-card"
          content="Надіслати"
          icon="send"
          disabled={isDisabled || phoneNumber.length < 19}
          loading={isDisabled}
          style={{ width: "50%" }}
        />
      </div>
    </form>
  );
};

export default RegistrationCard;
