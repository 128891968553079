import React, { Component } from "react";
import { connect } from "react-redux";
import "./archive-bills-card.css";
import DatePicker, { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/uk";

import "react-datepicker/dist/react-datepicker.css";
import { ArchivesApiService } from "../../services/archives-api-service/archives-api-service";
import MaterializeService from "../../services/materialize-sevices/materialize-js";
import ModalArchiveBills from "../../components/modal-archive-bills/modal-archive-bills";
import { isAuth } from "../../actions";
import { Button } from "semantic-ui-react";
registerLocale("uk", ptBR);

const archivesApi = new ArchivesApiService();
const materialize = new MaterializeService();
const { fetchArchivesBillsApi } = archivesApi;
const { toastSend, initModal } = materialize;
class ArchiveBillsCard extends Component {
  state = {
    startDate: new Date(),
    endDate: new Date(),
    isLoading: false,
    bills: []
  };

  archiveModal = React.createRef();

  componentDidMount() {
    this.archiveModalInstanse = initModal(this.archiveModal.current);
  }

  handleChangeStart = date => {
    this.setState({
      startDate: date
    });
  };

  handleChangeEnd = date => {
    this.setState({
      endDate: date
    });
  };

  mutationDate = () => {
    const { startDate, endDate } = this.state;
    const nowDay = new Date().getDate();
    const nowMonth = new Date().getMonth();
    const nowYear = new Date().getFullYear();
    const startMonth = startDate.getMonth() + 1;
    const startDay = startDate.getDate();
    const startYear = startDate.getFullYear();
    const endMonth = endDate.getMonth() + 1;
    const endDay = endDate.getDate();
    const endYear = endDate.getFullYear();
    const StartPeriod = `${startYear}-${
      startMonth < 10 ? "0" + startMonth : startMonth
    }-${startDay < 10 ? "0" + startDay : startDay}T00:00:00`;
    const EndPeriod =
      nowDay !== endDay || nowMonth + 1 !== endMonth || nowYear !== endYear
        ? `${endYear}-${endMonth < 10 ? "0" + endMonth : endMonth}-${
            endDay < 10 ? "0" + endDay : endDay
          }T00:00:00`
        : new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .split(".")[0];
    return { StartPeriod, EndPeriod };
  };

  handleCheckDates = () => {
    const { startDate, endDate } = this.state;
    return (
      startDate.getTime() > endDate.getTime() &&
      (startDate.getDay() !== endDate.getDay() ||
        startDate.getMonth() !== endDate.getMonth() ||
        startDate.getFullYear() !== endDate.getFullYear())
    );
  };

  handleSubmitArchives = async () => {
    try {
      const { Ref_Key } = this.props.contract;
      const { token } = this.props;
      if (this.handleCheckDates()) {
        return toastSend("Початкова дата не може бути більшою за кінцеву");
      }
      this.setState({ isLoading: true });
      const { StartPeriod, EndPeriod } = this.mutationDate();
      const body = { StartPeriod, EndPeriod, Ref_Key };
      const { Error, Result } = await fetchArchivesBillsApi(body, token);
      if (Error) {
        toastSend("За обраний період оплат не знайдено");
        this.setState({ isLoading: false });
        return;
      }
      this.setState({ isLoading: false, bills: Result });
      this.archiveModalInstanse.open();
    } catch (error) {
      const { isAuth, history } = this.props;
      isAuth(null);
      localStorage.clear();
      history.push("/login");
    }
  };

  render() {
    const { contract } = this.props;
    const { isLoading, bills } = this.state;
    return (
      <div className="archives-card">
        <div className="contracts-content-archives">
          <div className="archive-contract-title bills-archive">
            {contract.number}
          </div>
          <Button
            className="archive-card-btn"
            primary
            icon="find"
            onClick={this.handleSubmitArchives}
            content="дивитися"
            disabled={isLoading}
            loading={isLoading}
          />
        </div>
        <div className="date-content-сard">
          <span className="helper-text date">Оберіть початкову дату</span>
          <DatePicker
            selected={this.state.startDate}
            onChange={this.handleChangeStart}
            dateFormat="dd MMMM yyyy"
            locale="uk"
            withPortal
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            minDate={new Date(2015, 0, 1, 0, 0, 0, 0)}
            maxDate={new Date()}
          />
          <span className="helper-text date">Оберіть кінцеву дату</span>
          <DatePicker
            selected={this.state.endDate}
            onChange={this.handleChangeEnd}
            dateFormat="dd MMMM yyyy"
            locale="uk"
            withPortal
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            minDate={new Date(2015, 0, 1, 0, 0, 0, 0)}
            maxDate={new Date()}
          />
        </div>
        <ModalArchiveBills list={bills} refModal={this.archiveModal} />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  isAuth: bool => dispatch(isAuth(bool))
});

export default connect(null, mapDispatchToProps)(ArchiveBillsCard);
