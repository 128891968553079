import React from "react";
import "./registration-header.css";

const yavirLogo = require('../../assets/logo.png')

const RegistrationHeader = () => {
  return (
    <div className="header-wrapper registration">
      <div className="header-content registration">
        <div className="logo-wrapper">
          <img
            className="logo"
            src={yavirLogo}
            alt="logo"
            onClick={() => window.open("https://yavir2000.com")}
          />
        </div>
        <div className="header-center">
          <div className="header-title">Особистий кабінет</div>
          <div className="header-title">ВХІД</div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationHeader;
