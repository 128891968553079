const getContragentsSuccess = payload => {
  return {
    type: 'GET_CONTRAGENTS_SUCCESS',
    payload
  }
}

const selectContragent = payload => {
  return {
    type: 'SELECT_CONTRAGENT',
    payload
  }
}

const deleteEmail = payload => {
  return {
    type: 'DELETE_EMAIL',
    payload
  }
}

const addEmail = payload => {
  return {
    type: 'ADD_EMAIL',
    payload
  }
}

export { getContragentsSuccess, selectContragent, deleteEmail, addEmail }
