import axios from 'axios'

class ArchivesApiService {
  fetchArchivesBillsApi = async (body, token) => {
    const { data } = await axios.post('/yavir2000/api/archives/bills', body, {
      headers: {
        Authorization: token
      }
    })

    return data
  }
}

export { ArchivesApiService }
