import React from "react";
import "./init-page.css";
const logo = require("../../../src/assets/logo.png");

const InitPage = () => {
  return (
    <div className="init-page">
      <img src={logo} alt="logo" />
      <div>Зачекайте. Йде завантаження додатку...</div>
    </div>
  );
};

export default InitPage;
