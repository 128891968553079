import { Modal, Button, Table, Icon } from "semantic-ui-react";
import { useState } from "react";

//styles
import "./appeals-modal.css";

//components
import AppealInfoModal from "../appeal-info-modal/appeal-info-modal";

const AppealsModal = ({ appeals, contractNumber, isOpened, handleClose }) => {
  const [selectedAppeal, setSelectedAppeal] = useState(null);

  const handleAppealClick = (appeal) => {
    setSelectedAppeal(appeal);
  };

  const closeAppealModal = () => {
    setSelectedAppeal(null);
  };

  return (
    <Modal open={isOpened} onClose={handleClose} className="appeals-modal">
      <Modal.Header className="appeals-modal-header">
        Заявки по угоді {contractNumber}
        <Icon name="close" onClick={handleClose} className="appeals-modal__close-icon" />
      </Modal.Header>
      <Modal.Content className="appeals-modal-content">
        {appeals && appeals.length > 0 ? (
          <Table celled selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>№ заявки</Table.HeaderCell>
                <Table.HeaderCell>Об' єкт</Table.HeaderCell>
                <Table.HeaderCell>Стан</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {appeals.map((appeal) => (
                <>
                  <Table.Row key={appeal.id} onClick={() => handleAppealClick(appeal)}>
                    <Table.Cell>№ {appeal.id}</Table.Cell>
                    <Table.Cell>{appeal.object_name}</Table.Cell>
                    <Table.Cell>{appeal.status_desc}</Table.Cell>
                  </Table.Row>
                </>
              ))}
              <AppealInfoModal
                appealNumber={selectedAppeal?.id}
                appealRefkey={selectedAppeal?.Ref_Key}
                objectRefkey={selectedAppeal?.object_Ref_Key}
                status={selectedAppeal?.status}
                isOpened={!!selectedAppeal}
                handleClose={closeAppealModal}
              />
            </Table.Body>
          </Table>
        ) : (
          <p>Заявки відсутні</p>
        )}
      </Modal.Content>
      <Modal.Actions className="appeals-modal-actions">
        <Button onClick={handleClose} color="red">
          Закрити
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AppealsModal;
