import React, { Component } from 'react'
import { connect } from 'react-redux'
import ArchiveBillsCard from '../archive-bills-card/archive-bills-card'
import './archive-bills.css'
import { isAuth } from '../../actions';

class ArchivesBillsPage extends Component {
  render () {
    const { contractsList, isRoot, history } = this.props
    return (
      <div className='archives-bills-page-wrapper'>
        {contractsList &&
          contractsList.map(contract => (
            <ArchiveBillsCard
              key={contract.Ref_Key}
              contract={contract}
              token={isRoot}
              history={history}
            />
          ))}
      </div>
    )
  }
}

const mapStateToProps = ({ contragents, registration }) => ({
  contractsList: contragents.contractsList,
  isRoot: registration.isRoot
})

const mapDispatchToProps = dispatch => ({
  isAuth: bool => dispatch(isAuth(bool))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArchivesBillsPage)
