import React, { Component } from "react";
import "./support-textarea.css";
import MaterializeService from "../../services/materialize-sevices/materialize-js";
import ButtonPreloader from "../button-preloader/button-preloader";

const materialize = new MaterializeService();
const { updateTextField } = materialize;

class SupportTextarea extends Component {
  componentDidMount() {
    updateTextField();
  }

  render() {
    const {
      text,
      onHandleChangeTextarea,
      onHandleSubmitClaim,
      selected,
      isLoading,
      textareaRef
    } = this.props;

    const disabledSubmit = () => {
      if (
        text.length === 0 ||
        selected === "" ||
        text.length > 1000 ||
        !text.trim()
      ) {
        return true;
      }
      return false;
    };

    const invalidLength = () => {
      if (text.length > 1000) {
        return "red-text";
      }
    };
    return (
      <div className="textarea-wrapper">
        <div className="input-field col s6 textarea">
          <i className="material-icons prefix">mode_edit</i>
          <textarea
            id="icon_prefix2"
            className="materialize-textarea"
            value={text}
            maxLength={1000}
            onChange={onHandleChangeTextarea}
            ref={textareaRef}
          />
          <label htmlFor="icon_prefix2">Введіть текст звернення</label>
          <span className={`helper-text ${invalidLength()}`}>
            {text.length}/1000
          </span>
        </div>
        {isLoading ? (
          <ButtonPreloader />
        ) : (
          <button
            className="btn textarea"
            onClick={() => onHandleSubmitClaim(true)}
            disabled={disabledSubmit()}
          >
            надіслати<i className="material-icons right">send</i>
          </button>
        )}
      </div>
    );
  }
}

export default SupportTextarea;
