import React from "react";
import "./style.css";
import TextArea from "../text-area/text-area";
import ButtonSubmit from "../modals/button-submit/submit-button";
import ModalContent from "../modals/modal-content/modal-content";
import ModalCloseButton from "../modals/modal-close-button/modal-close-button";
import ButtonPreloader from "../button-preloader/button-preloader";

const ModalRevision = ({
  modal,
  handleComment,
  placeholder,
  commentText,
  id,
  handleSubmit,
  isLoading
}) => {
  return (
    <div className="modal revision-claim" ref={modal}>
      <ModalContent>
        <ModalCloseButton />
        <div style={{ textAlign: "center" }}>
          Будь ласка, вкажіть причину повернення заявки на доопрацювання!
        </div>
      </ModalContent>
      <div className="modal-footer-revision-claim">
        <TextArea
          text={commentText}
          onhandleChange={handleComment}
          placeholder={placeholder}
          id={id}
        />
        {isLoading ? (
          <ButtonPreloader />
        ) : (
          <ButtonSubmit
            handleSubmit={() => handleSubmit(false)}
            disabled={commentText.length > 10 && commentText.trim()}
            text={"повернути"}
          />
        )}
      </div>
    </div>
  );
};

export default ModalRevision;
