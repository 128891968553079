const initialState = {
  payment: null
};

const payment = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_PAYMENT":
      return {
        ...state,
        payment: payload
      };

    default:
      return state;
  }
};

export default payment;
