import axios from "axios";

export const createSignature = async (body, token, phpnenumber) => {
  const { data } = await axios.post(
    `/yavir2000/api/liqpay/createSignature/${phpnenumber}`,
    body,
    {
      headers: {
        Authorization: token
      }
    }
  );
  return data;
};

export const getLiqpayOrganizations = async () => {
  const { data } = await axios.get(`/yavir2000/api/liqpay/organizations`);
  return data;
};

export const redirectToLiqpay = async token => {
  const { data } = await axios.get(`/yavir2000/api/liqpay/redirect`, {
    headers: {
      Authorization: token
    }
  });
  return data;
};
