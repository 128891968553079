const showContragentTitle = (payload) => {
    return {
        type: 'SHOW_CONTRAGENT_TITLE',
        payload
    }
}

const showContractTitle = payload => {
    return {
        type: 'SHOW_CONTRACT_TITLE',
        payload
    }
}


export {
    showContragentTitle, showContractTitle
}