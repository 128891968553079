import _ from "lodash";

const initialState = {
  text: "",
  selected: "",
  currentTypeClaim: "",
  archiveClaimSelected: "",
  currentClaimsList: []
};

const textAreaText = (state = initialState, { type, payload }) => {
  switch (type) {
    case "CHANGE_TEXT_AREA":
      return {
        ...state,
        text: payload
      };

    case "CHANGE_SELECT":
      return {
        ...state,
        selected: payload
      };

    case "CHANGE_CURRENT_TYPE_CLAYM":
      return {
        ...state,
        currentTypeClaim: payload
      };

    case "FETCH_CURRENT_CLAIMS_LIST_SUCCESS":
      return {
        ...state,
        currentClaimsList: payload
      };

    case "UPDATE_CLAIMS_LIST":
      const claimsforUpdate = _.cloneDeep(state.currentClaimsList);
      const idx = claimsforUpdate.findIndex(
        claim => +claim.id_claim === +payload.id
      );
      if (idx !== -1) {
        claimsforUpdate[idx].online_status = payload.type;
      }
      return {
        ...state,
        currentClaimsList: claimsforUpdate
      };

    case "DELETE_CURRENT_CLAIM":
      const currentClaimsFilter = state.currentClaimsList.filter(
        item => +item.id_claim !== +payload
      );
      return {
        ...state,
        currentClaimsList: currentClaimsFilter
      };

    default:
      return state;
  }
};

export default textAreaText;
