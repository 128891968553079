import React from "react";

import "./button-preloader.css";

const ButtonPreloader = ({ isMargin = 0, className = "" }) => {
  return (
    <div
      style={{ marginTop: isMargin }}
      className={`preloader-wrapper small active ${className}`}
    >
      <div className="spinner-layer spinner-green-only">
        <div className="circle-clipper left">
          <div className="circle" />
        </div>
        <div className="gap-patch">
          <div className="circle" />
        </div>
        <div className="circle-clipper right">
          <div className="circle" />
        </div>
      </div>
    </div>
  );
};

export default ButtonPreloader;
