import React from "react";
import './button-up.css';
const ScrollTop = ({ onhandleClickUp }) => {
  return (
    <button
      onClick={onhandleClickUp}
      className="btn-floating btn-large waves-effect waves-light button-up"
    >
      <i className="material-icons">arrow_upward</i>
    </button>
  );
};

export default ScrollTop;
