import React from 'react';
import './error-page.css'

const ErrorPage = () => {
    return (
        <div className="error-page-wrapper">
            <div className="error-content">
                Вибачте. В даний момент ідуть технічні роботи. Зайдіть пізніше.
            </div>
        </div>
    );
}

export default ErrorPage;