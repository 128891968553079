const initialState = {
  email: null,
  phonenumber: null,
  isTelegramAkk: false,
  currentIdSetting: null
}

const settings = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'CHANGE_EMAIL':
      return {
        ...state,
        email: payload
      }

    case 'FETCH_SETTINGS_SUCCESS':
      return {
        ...payload
      }

    case 'DELETE_TELEGRAM_AKK':
      return {
        ...state,
        isTelegramAkk: null
      }

    default:
      return state
  }
}

export default settings
