import axios from 'axios'

export default class EmailService {
  sendMail = async (body, token) => {
    const { data } = await axios.post('/yavir2000/api/email', body, {
      headers: {
        'Authorization': token
      }
    })
    return data
  }
}