const initialState = {
    emailModalInstanse: null,
}

const modalsInstances = (state = initialState, { type, payload }) => {
    switch (type) {
        case 'INIT_EMAIL_MODAL':
            return {
                ...state,
                emailModalInstanse: payload
            }
        
        default:
            return state
    }
}

export default modalsInstances;