import React from 'react'
import './claims-messages-list.css'
import moment from 'moment'

const CurrentMessagesList = ({ list }) => {
  return (
    <React.Fragment>
      {list.map((item, idx) => {
        const className = item.type_message === 1 ? 'message' : 'message owner'
        const sender = item.type_message === 1 ? 'Ви' : 'Явір2000'
        return (
          <div className={className} key={idx}>
            <div className='message-content z-depth-1'>
              <b>{sender}</b>: {item.text_message}
              <br />
              <b>Дата</b>:{' '}
              {moment(item.date_message).format('DD.MM.YYYY, H:mm:ss')}
            </div>
          </div>
        )
      })}
    </React.Fragment>
  )
}

export default CurrentMessagesList
