import React, { Component } from "react";
import RegistrationHeader from "../../components/registration-header/registration-header";
import RegistrationCard from "../../components/registration-card/registration-card";
import { connect } from "react-redux";
import { getPhoneNumber } from "../../actions/get-phone-number";

import SoneApiService from "../../services/c1-api-services/c1-api-service";
import PasswordService from "../../services/password-services/password-services";
import {
  isAuth,
  getContragentsSuccess,
  changePassword,
  changeCheckPassword
} from "../../actions";

import ModalRegistration from "../../components/modal-registration/modal-registration";
import MaterializeService from "../../services/materialize-sevices/materialize-js";
import messages from "../../services/messages/messages";

import "./registration-page.css";
import PasswordCard from "../../components/password-card/password-card";
import Footer from "../footer/footer";

const materialize = new MaterializeService();
const apiService = new SoneApiService();
const passwordService = new PasswordService();

const { savePassword, checkPassword } = passwordService;
const { getUser, getPinCode, checkPin } = apiService;
const { initModal, toastSend, updateTextField } = materialize;
const { noNumberFound, noPIN } = messages;

class RegistrationPage extends Component {
  isMounting = false;
  state = {
    phoneNumberCurrent: "",
    pinInpit: "",
    isLoading: false,
    dbUser: null,
    isResponse: null,
    failedPassword: null,
    failedPin: null,
    isForgotPassword: null,
    sendTimer: null,
    timeToReplay: 0,
    typeSend: null
  };

  modalRegistration = React.createRef();
  modalPassword = React.createRef();
  focusInputPassword = React.createRef();
  focusInputPIN = React.createRef();

  componentDidMount = () => {
    this.isMounting = true;
    this.modalInstances = initModal(this.modalRegistration.current);
  };

  componentWillUnmount = () => {
    this.isMounting = false;
    clearInterval(this.intervalToSend);
  };

  onHandlerChangeInput = e => {
    if (this.isMounting) {
      this.setState(prevState => ({
        phoneNumberCurrent: e.length < 4 ? prevState.phoneNumberCurrent : e
      }));
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.isMounting) {
      if (prevProps.isPenalty !== this.props.isPenalty) {
        this.modalInstances = initModal(this.modalRegistration.current);
        updateTextField();
      }
      if (prevState.isResponse !== this.state.isResponse) {
        this.modalInstancesPassword = initModal(this.modalPassword.current);
      }
    }
  };

  onHandleSubmit = async e => {
    try {
      if (this.isMounting) {
        e.preventDefault();
        this.onLoading(true);
        const {
          phoneNumber,
          history,
          isAuth,
          getContragents,
          changePassword,
          changeConfirmPassword
        } = this.props;
        const { dbUser, isForgotPassword } = this.state;
        const data = {
          phonenumber: this.filterForcheckPin(phoneNumber),
          pin: this.state.pinInpit,
          isLogIn: true
        };
        const response = await checkPin(data);
        const { check, token, error, refreshToken } = response;
        if (error) {
          this.errorHandler();
          return;
        }
        if (check) {
          const data = { phonenumber: phoneNumber };
          const resp = await getUser(data);
          const { response } = resp;
          if (!dbUser) {
            const { save } = await this.savePassword();
            if (!save) {
              this.errorHandler();
              return;
            }
            toastSend(
              isForgotPassword ? "Пароль змінено!" : `Реєстрація успішна!`
            );
          }
          const { Error, Result } = response;
          if (!Error) {
            isAuth(token);
            localStorage.setItem("auth", token);
            localStorage.setItem("rfr", refreshToken);
            getContragents(Result);
            changeConfirmPassword("");
            changePassword("");
            history.push("/contracts");
            return;
          }
          this.onLoading(false);
          toastSend(noNumberFound, 10000);
        } else {
          this.onLoading(false);
          this.setState({ failedPin: "Невірний PIN" });
          toastSend(noPIN);
          this.focusInputPIN.current.focus();
        }
      }
    } catch (error) {
      this.errorHandler(error);
    }
  };

  onHandleChangePinInput = e => {
    if (this.isMounting) {
      const { value } = e.target;
      this.setState({ pinInpit: value });
    }
  };

  onHandleClick = async e => {
    try {
      if (this.isMounting) {
        e.preventDefault();
        this.props.changePassword("");
        this.props.changeConfirmPassword("");
        this.onLoading(true);
        const data = { phonenumber: this.filterNumber() };
        const response = await getUser(data);
        const { Error, dbUser, error } = response;
        if (error) {
          this.errorHandler();
          return;
        }
        if (!Error) {
          this.onLoading(false);
          this.setState({ dbUser, isResponse: response });
          this.modalInstancesPassword.open();
          this.focusInputPassword.current.focus();
          return;
        }
        this.onLoading(false);
        toastSend(noNumberFound, 10000);
      }
    } catch (error) {
      this.errorHandler(error);
    }
  };

  errorHandler = error => {
    if (error.response && error.response.status === 429) {
      toastSend("Ви перевищили ліміт запитів на сервер. Спробуйте пізніше!");
      return this.onLoading(false);
    }
    toastSend("В даний час ідуть технічні роботи, спробуйте пізніше!");
    this.onLoading(false);
  };

  filterForcheckPin = number => {
    return number
      .split("")
      .filter(n => n !== "+")
      .join("");
  };

  filterNumber = () => {
    const { getPhoneNumber } = this.props;
    const { phoneNumberCurrent } = this.state;
    const filter = phoneNumberCurrent
      .split(" ")
      .join("")
      .split("")
      .filter(n => n !== "(")
      .filter(n => n !== ")")
      .join("");
    getPhoneNumber(filter);
    return filter;
  };

  onLoading = bool => {
    if (this.isMounting) {
      this.setState({
        pinInpit: "",
        isLoading: bool
      });
    }
  };

  closeModalPassword = e => {
    e.preventDefault();
    this.props.changePassword("");
    this.props.changeConfirmPassword("");
    this.modalInstancesPassword.close();
  };

  onCloseModal = e => {
    if (this.isMounting) {
      clearInterval(this.intervalToSend);
      e.preventDefault();
      this.props.changePassword("");
      this.modalInstances.close();
    }
  };

  onHandleSubmitPassword = async e => {
    try {
      e.preventDefault();
      clearInterval(this.intervalToSend);
      const { dbUser } = this.state;
      const phoneToPin = this.filterForcheckPin(this.filterNumber());
      const dataToPin = { phoneToPin };
      this.onLoading(true);
      if (!dbUser) {
        const response = await getPinCode(dataToPin);
        const { error, timeOut, typeSend } = response;
        if (timeOut) {
          this.setState({
            sendTimer: timeOut,
            timeToReplay: timeOut,
            typeSend
          });
          this.timerToSend();
        }
        if (error) {
          this.errorHandler();
          return;
        }
        await this.modalInstances.open();
        this.focusInputPIN.current && this.focusInputPIN.current.focus();
        this.setState({
          isShowCard: false
        });
        this.onLoading(false);
      } else {
        const { phoneNumber, password } = this.props;
        const { passwordRes, error } = await checkPassword({
          phoneNumber,
          password
        });
        if (error) {
          this.errorHandler();
          return;
        }
        if (passwordRes) {
          const { error, timeOut, typeSend } = await getPinCode(dataToPin);
          if (timeOut) {
            this.setState({
              sendTimer: timeOut,
              timeToReplay: timeOut,
              typeSend
            });
            this.timerToSend();
          }
          if (error) {
            this.errorHandler();
            return;
          }
          this.modalInstancesPassword.close();
          this.onLoading(false);
          this.setState({
            isShowCard: false,
            failedPassword: null
          });
          await this.modalInstances.open();
          this.focusInputPIN.current.focus();
        }
        if (!passwordRes) {
          this.props.changePassword("");
          this.setState({ failedPassword: "Пароль невірний" });
          toastSend("Ви ввели невірний пароль");
          this.onLoading(false);
          this.focusInputPassword.current.focus();
        }
      }
    } catch (error) {
      this.errorHandler(error);
    }
  };

  savePassword = async () => {
    const { isForgotPassword } = this.state;
    const { phoneNumber, password } = this.props;
    const body = {
      password,
      phonenumber: phoneNumber,
      isForgotPassword
    };
    const save = await savePassword(body);
    return save;
  };

  forgotPassword = () => {
    this.setState({
      isForgotPassword: "forgot",
      dbUser: null
    });
  };

  timerToSend = () => {
    this.intervalToSend = setInterval(() => {
      this.setState({
        sendTimer: this.state.sendTimer - 1
      });
      if (this.state.sendTimer === 0) {
        this.setState({
          sendTimer: null
        });
        clearInterval(this.intervalToSend);
      }
    }, 1000);
  };

  replaySend = e => {
    this.onHandleSubmitPassword(e);
  };

  render() {
    const {
      phoneNumberCurrent,
      pinInpit,
      isLoading,
      dbUser,
      failedPassword,
      failedPin,
      isForgotPassword,
      sendTimer,
      timeToReplay,
      typeSend
    } = this.state;

    return (
      <React.Fragment>
        <div className="registration-wrapper">
          <RegistrationHeader />
          <RegistrationCard
            phoneNumber={phoneNumberCurrent}
            onHandlerChangeInput={this.onHandlerChangeInput}
            onHandleClick={this.onHandleClick}
            isDisabled={isLoading}
          />
          <ModalRegistration
            modalRegistration={this.modalRegistration}
            onCloseModal={this.onCloseModal}
            onInputChange={this.onHandleChangePinInput}
            value={pinInpit}
            onHandleSubmit={this.onHandleSubmit}
            isDisabled={isLoading}
            failedPin={failedPin}
            sendTimer={sendTimer}
            replaySend={this.replaySend}
            timeToReplay={timeToReplay}
            typeSend={typeSend}
            focusInputPIN={this.focusInputPIN}
          />
          <PasswordCard
            modal={this.modalPassword}
            dbUser={dbUser}
            closeModal={this.closeModalPassword}
            phoneNumber={phoneNumberCurrent}
            onHandleSubmitPassword={this.onHandleSubmitPassword}
            isDisabled={isLoading}
            failedPassword={failedPassword}
            forgotPassword={this.forgotPassword}
            isForgotPassword={isForgotPassword}
            focusInputPassword={this.focusInputPassword}
            {...this.props}
          />
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ registration }) => ({
  phoneNumber: registration.phoneNumber,
  isRoot: registration.isRoot,
  password: registration.password
});
const mapDispatchToProps = dispatch => ({
  getPhoneNumber: number => dispatch(getPhoneNumber(number)),
  isAuth: bool => dispatch(isAuth(bool)),
  getContragents: list => dispatch(getContragentsSuccess(list)),
  changePassword: pass => dispatch(changePassword(pass)),
  changeConfirmPassword: pass => dispatch(changeCheckPassword(pass))
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPage);
