import React, { useState, Fragment, useEffect, useRef } from "react";
import { Button, Icon } from "semantic-ui-react";
import { useSelector } from "react-redux";
import Requisites from "../../components/requisites/requisites";
import LiqPay from "../../components/liqpay/ligpay";
import Qr from "../../components/qr/qr";
import { Redirect } from "react-router-dom";

import "./payment-page.css";

const color = (sum) => {
  if (!sum) return;
  if (sum.includes("-") || sum.includes("Відсутня")) {
    return "green";
  }
  return "red";
};

const PrivatContent = ({ iban, edrpou, contractNumber, orgName, refKey, currentContragent }) => {
  if (iban === "немає рахунку") {
    return <div>Для даної угоди поки що немає можливості здійснювати оплату</div>;
  }
  return (
    <Fragment>
      <div>
        <Requisites item={iban} textTitle={"Номер рахунку"} />
        <Requisites item={edrpou} textTitle={"ЄДРПОУ"} />
        <Requisites item={contractNumber} textTitle={"Номер угоди"} />
        <Requisites item={orgName} textTitle={"Одержувач"} isFirm={true} />
        <Requisites
          item={{
            refKey,
            contractNumber,
            contragentName: currentContragent.fullname,
          }}
          isDescription={true}
          textTitle={"Призначення"}
        />
      </div>
      <div className="warning">
        Увага! У призначенні платежу міститься службова інформація. <br />
        При внесенні змін, зарахування Вашого платежу може бути затримано на невизначений термін!
      </div>
    </Fragment>
  );
};

const ModalContent = ({
  type,
  contractNumber,
  types,
  iban,
  edrpou,
  orgName,
  refKey,
  currentContragent,
  token,
  history,
  phonenumber,
  Organization_Ref_Key,
  isAuth,
  isSuccess,
  setIsSuccess,
  handleClickType,
  sum,
  setSum,
  textDebt,
  summDebt,
  summaOneEs,
}) => {
  if (!type) {
    return (
      <div className="modal-content payments">
        <div className="payment-text-content">
          <div style={{ marginTop: "15px" }}>Оплата по угоді {contractNumber}</div>
          <div style={{ color: color(summaOneEs) }}>
            {textDebt.text} {summDebt}
          </div>
          <div>Оберіть тип оплати:</div>
        </div>
      </div>
    );
  }
  if (type === types.privat) {
    return (
      <Fragment>
        <div className="modal-content payments">
          <div className="payment-text-content">
            <div>Реквізити для оплати по угоді {contractNumber}</div>
            <div> ("Приват24", "ПУМБ-online" і т.і.)</div>
            <div style={{ color: color(summaOneEs) }}>
              {textDebt.text} {summDebt}
            </div>
          </div>
          <PrivatContent
            iban={iban}
            refKey={refKey}
            contractNumber={contractNumber}
            currentContragent={currentContragent}
            edrpou={edrpou}
            orgName={orgName}
          />
        </div>
      </Fragment>
    );
  }
  if (type === types.liqpay) {
    const int = +sum;

    return (
      <div className="modal-content payments">
        {!isSuccess && (
          <Fragment>
            <div className="payment-text-content">
              <div style={{ marginTop: "5px" }}>Оплата за угодою {contractNumber} через LiqPay</div>
              <div style={{ color: color(summaOneEs) }}>
                {textDebt.text} {summDebt}
              </div>
              <div style={{ marginBottom: "10px" }}>Введіть суму:</div>
            </div>
          </Fragment>
        )}
        {isSuccess && (
          <div className="payment-text-content">
            <div style={{ marginTop: "5px", color: "red", fontSize: "1.5rem" }}>
              Підтверження оплати!
            </div>
            <div style={{ fontSize: "1rem" }}> Угода: {contractNumber}</div>
            <div style={{ color: color(summaOneEs) }}>
              {textDebt.text} {summDebt}
            </div>
            <div style={{ fontSize: "1rem", marginBottom: "10px" }}>
              Сума до сплати (з комісією за зручність 2.83%): {int && (int * 1.028278).toFixed(2)}{" "}
              грн.
            </div>
          </div>
        )}

        <LiqPay
          Ref_Key={refKey}
          token={token}
          history={history}
          isAuth={isAuth}
          phonenumber={phonenumber}
          Organization_Ref_Key={Organization_Ref_Key}
          contragent={currentContragent}
          number={contractNumber}
          handleClickType={handleClickType}
          setIsSuccess={setIsSuccess}
          setSum={setSum}
        />
      </div>
    );
  }
  if (type === types.qr) {
    return (
      <div className="modal-content payments">
        <Fragment>
          <div className="payment-text-content">
            <div style={{ marginTop: "5px" }}>Оплата за угодою {contractNumber} через QR</div>
            <div style={{ color: color(summaOneEs) }}>
              {textDebt.text} {summDebt}
            </div>
          </div>
        </Fragment>
        <Qr
          iban={iban}
          refKey={refKey}
          contractNumber={contractNumber}
          currentContragent={currentContragent}
          handleClickType={handleClickType}
          edrpou={edrpou}
          orgName={orgName}
        />
      </div>
    );
  }
};

const ButtonContent = ({ types, type, handleClickType }) => {
  return (
    <Fragment>
      {!type && (
        <div className="buttons-payments">
          <Button
            className="modal-payments-btn liqpay"
            basic
            color="green"
            content="LIQPAY"
            icon="angle double right"
            onClick={() => handleClickType(types.liqpay)}
          />
          <Button
            className="modal-payments-btn privat_24"
            basic
            onClick={() => handleClickType(types.privat)}
            content="Iнші способи"
            icon="list ol"
            color="blue"
          />
          <Button
            className="modal-payments-btn qr"
            onClick={() => handleClickType(types.qr)}
            content="Оплата через QR"
            icon="qrcode"
            style={{ border: "1px solid black" }}
          />
        </div>
      )}
      {type === types.privat && (
        <div className="buttons-payments privat">
          <Button
            className="privat-btn back"
            style={{ margin: "0 10px" }}
            content="назад"
            icon="arrow alternate circle left"
            color="red"
            onClick={() => handleClickType(null)}
          />
        </div>
      )}
    </Fragment>
  );
};

const PaymentPage = (props) => {
  const [type, setType] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [sum, setSum] = useState(null);
  const payment = useSelector((state) => state.payment.payment);

  const contragent = useSelector((state) => state.contragents.currentContragent);

  const prevContragent = useRef();

  useEffect(() => {
    if (prevContragent.current) return;
    prevContragent.current = contragent;
  }, [contragent]);

  useEffect(() => {
    if (prevContragent.current !== contragent) {
      setType(null);
      setIsSuccess(false);
      props.history.push("/contracts");
    }
  }, [contragent, props.history]);

  const types = {
    liqpay: "Liqpay",
    privat: "Privat24",
    qr: "QR",
  };

  const handleClickType = (type = null) => {
    setType(type);
  };

  const handleClose = () => {
    if (!type) {
      return props.history.push("/contracts");
    }
    if (type) {
      return setType(null);
    }
  };

  if (!payment) {
    return <Redirect to="/contracts" />;
  }

  return (
    <div className="payment-page-wrapper">
      <div className="card payment-page">
        <ModalContent
          {...payment}
          type={type}
          types={types}
          handleClickType={handleClickType}
          isSuccess={isSuccess}
          setIsSuccess={setIsSuccess}
          sum={sum}
          setSum={setSum}
        />
        {type !== types.privat && type !== types.qr && (
          <div className="payment-modal-actions">
            <ButtonContent handleClickType={handleClickType} type={type} types={types} />
          </div>
        )}
        {type !== types.liqpay && (
          <Icon name="close" className="close-payment-page" onClick={handleClose} />
        )}
      </div>
    </div>
  );
};

export default PaymentPage;
