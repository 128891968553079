const initialState = {
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear()
  };
  
  const date = (state = initialState, { type, payload }) => {
    switch (type) {
      case "SELECT_MONTH":
        return {
          ...state,
          month: payload
        };
    
    case "SELECT_YEAR":
        return {
          ...state,
          year: payload
        };

      default:
        return state;
    }
  };
  
  export default date;