import React, { Component } from "react";
import { connect } from "react-redux";
import SupportService from "../../services/support-service/support-service";
import {
  isAuth,
  changeCurrentClaym,
  deleteCurrentClaim,
  updateCurrentClaims
} from "../../actions";
import MaterializeService from "../../services/materialize-sevices/materialize-js";
import Preloader from "../../components/preloader/preloader";
import CurrentMessagesList from "../../components/claims-messages-list/claims-messages-list";
import "./claims-messages.css";
import ClaymsMessagesActions from "../../components/current-clayms-messages-actions/current-clayms-messages-actions";
import SupportTextarea from "../../components/support-textarea/support-textarea";
import ModalRating from "../../components/modal-rating/modal-rating";
import ModalRevision from "../../components/modal-revision-claim/modal-revision-claim";

const supportService = new SupportService();
const materialize = new MaterializeService();
const {
  getCurrentMessagesApi,
  submitNewClaimApi,
  closeClaimWithoutRating
} = supportService;
const { toastSend, initModal, autoResizeTextArea } = materialize;

class ClaimsMessages extends Component {
  isMounting = false;
  id = 0;
  state = {
    messages: [],
    isLoading: false,
    isLoadingButton: false,
    isLoadingButtonSubmit: false,
    text: "",
    isShowTextArea: false,
    rating: 1,
    onlineStatus: null,
    modalText: ""
  };

  modalRating = React.createRef();
  textareaRef = React.createRef();
  modalTextArearef = React.createRef();

  componentDidMount() {
    this.isMounting = true;
    this.socketChange();
    this.fetchCurrentMessages();
  }

  socketChange = () => {
    const { socket, match, type } = this.props;
    this.id = match.params.id;
    if (type !== "current") return;
    socket.on("answerQuestion", data => {
      if (+this.id === data.id_claim) {
        this.setState({
          messages: [...this.state.messages, data]
        });
      }
    });
    socket.on("updateMessagesAfterClose", data => {
      if (+this.id === +data.id) {
        this.setState({
          onlineStatus: data.type
        });
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { appWrapper } = this.props;
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.id = +this.props.match.params.id;
      this.fetchCurrentMessages();
    }
    if (prevState.isShowTextArea !== this.state.isShowTextArea) {
      appWrapper.scrollTop = appWrapper.scrollHeight;
    }

    if (prevState.text !== this.state.text) {
      appWrapper.scrollTop = appWrapper.scrollHeight;
    }
  }

  componentWillUnmount() {
    this.isMounting = false;
    const { socket, type } = this.props;
    if (type === "current") {
      socket.removeListener("answerQuestion");
      socket.removeListener("updateMessagesAfterClose");
    }
    this.props.changeCurrentClaym(null);
  }

  fetchCurrentMessages = async () => {
    const {
      match,
      history,
      isRoot,
      type,
      changeCurrentClaym,
      currentContragent
    } = this.props;
    try {
      const { id } = match.params;
      this.setState({ isLoading: true });
      const {
        result,
        error,
        typeError,
        onlineStatus
      } = await getCurrentMessagesApi({ id, type }, isRoot);
      if (typeError || !result.length) {
        toastSend("Ви намагаєтесь ввести невірні параметри");
        this.setState({ isLoading: false });
        if (type === "current") {
          return history.push("/support/current");
        } else {
          return history.push("/support/archive");
        }
      }

      if (
        !currentContragent ||
        result[0].contragent_name !== currentContragent.fullname
      ) {
        this.setState({ isLoading: false });
        if (type === "current") {
          return history.push("/support/current");
        } else {
          return history.push("/support/archive");
        }
      }
      changeCurrentClaym(id);
      if (error) {
        this.setState({ isLoading: false });
        toastSend("Щось пішло не так, спробуйте ще");
        return;
      }
      if (!this.isMounting) return;
      this.setState({ messages: result, isLoading: false, onlineStatus });
      this.modalRatingInstanse = initModal(this.modalRating.current);
      this.modalTextAreaInst = initModal(this.modalTextArearef.current);
    } catch (error) {
      this.logOut();
    }
  };

  logOut = () => {
    this.props.isAuth(null);
    localStorage.clear();
    this.props.history.push("/login");
  };

  onHandleChangeTextarea = e => {
    this.setState({ text: e.target.value });
  };

  onHandleClickNewMessage = () => {
    this.setState({ isShowTextArea: !this.state.isShowTextArea });
  };

  onHandleSubmitMessage = async (isNew = true) => {
    try {
      const { text, modalText } = this.state;
      const { isRoot, typeClaim, updateClaims } = this.props;
      let data;

      if (isNew) {
        data = {
          text,
          idClaim: +typeClaim
        };
      } else {
        data = {
          text: modalText,
          idClaim: +typeClaim,
          isRevision: true,
          contragent: this.props.currentContragent.fullname
        };
      }
      this.setState({ isLoadingButton: true });
      const { result, error } = await submitNewClaimApi(data, isRoot);
      this.setState({ isLoadingButton: false });
      if (error && error === 500) {
        toastSend("Щось пішло не так, спробуйте ще раз");
        return;
      }
      if (error && error === 400) {
        toastSend("Максимальна кількість символів - 1000");
        return;
      }
      this.setState({
        messages: [...this.state.messages, result[0]],
        text: "",
        onlineStatus: 0
      });
      updateClaims({ id: typeClaim, type: 0 });
      if (isNew) {
        autoResizeTextArea(this.textareaRef.current);
        this.props.socket.emit("revisionClaim", +typeClaim);
      }
      this.modalTextAreaInst && this.modalTextAreaInst.close();
    } catch (error) {
      this.logOut();
    }
  };

  onHandleClickWithoutRating = async () => {
    try {
      const { isRoot, match, deleteCurrentClaim, history } = this.props;
      const { id } = match.params;
      const typeClose = 1;
      this.setState({
        isLoadingButtonSubmit: true
      });
      const { error } = await closeClaimWithoutRating(
        { id: +id, typeClose },
        isRoot
      );
      if (error) {
        toastSend("Щось пішло не так, спробуйте ще раз");
        return;
      }
      deleteCurrentClaim(id);
      this.setState({ isLoadingButtonSubmit: false });
      history.push("/support/current");
      toastSend(`Заявка №${id} перенесена до архіву`);
    } catch (error) {
      this.logOut();
    }
  };

  submitWithRating = async () => {
    try {
      const { isRoot, match, deleteCurrentClaim, history } = this.props;
      const { rating } = this.state;
      const { id } = match.params;
      const typeClose = 1;
      this.setState({
        isLoadingButtonSubmit: true
      });
      const { error } = await closeClaimWithoutRating(
        { id: +id, typeClose, rating },
        isRoot
      );
      if (error) {
        toastSend("Щось пішло не так, спробуйте ще раз");
        return;
      }
      this.setState({ isLoadingButtonSubmit: false });
      deleteCurrentClaim(id);
      history.push("/support/current");
      toastSend(`Заявка №${id} перенесена до архіву`);
    } catch (error) {
      this.logOut();
    }
  };

  onHandleClickWithRating = () => {
    this.modalRatingInstanse.open();
  };

  closeModal = () => {
    this.modalRatingInstanse.close();
  };

  onStarClick = nextValue => {
    this.setState({ rating: nextValue });
  };

  returnToRevision = async () => {
    this.modalTextAreaInst.open();
  };

  handleChangeModalTextArea = e => {
    this.setState({
      modalText: e.target.value
    });
  };

  render() {
    const {
      isLoading,
      messages,
      text,
      isShowTextArea,
      isLoadingButton,
      rating,
      isLoadingButtonSubmit,
      onlineStatus
    } = this.state;

    const { type } = this.props;
    if (isLoading) {
      return <Preloader />;
    }
    return (
      <div className="current-messages-wrapper">
        <div className="messages-content z-depth-1">
          <CurrentMessagesList list={messages} />
          {type === "current" && (
            <ClaymsMessagesActions
              onHandleClickWithoutRating={this.onHandleClickWithoutRating}
              onHandleClickNewMessage={this.onHandleClickNewMessage}
              onHandleClickWithRating={this.onHandleClickWithRating}
              isLoading={isLoadingButtonSubmit}
              onlineStatus={onlineStatus}
              returnToRevision={this.returnToRevision}
            />
          )}
          {isShowTextArea && onlineStatus !== 2 && (
            <div className="new-message">
              <SupportTextarea
                text={text}
                onHandleChangeTextarea={this.onHandleChangeTextarea}
                onHandleSubmitClaim={this.onHandleSubmitMessage}
                isLoading={isLoadingButton}
                textareaRef={this.textareaRef}
              />
            </div>
          )}
        </div>
        <ModalRating
          modalRating={this.modalRating}
          rating={rating}
          onStarClick={this.onStarClick}
          submitWithRating={this.submitWithRating}
          isLoading={isLoadingButtonSubmit}
          closeModal={this.closeModal}
        />
        <ModalRevision
          modal={this.modalTextArearef}
          commentText={this.state.modalText}
          id={"1"}
          placeholder={"Введіть текст"}
          handleComment={this.handleChangeModalTextArea}
          handleSubmit={this.onHandleSubmitMessage}
          isLoading={this.state.isLoadingButton}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ registration, textAreaText, contragents }) => ({
  isRoot: registration.isRoot,
  phonenumber: registration.phoneNumber,
  typeClaim: textAreaText.currentTypeClaim,
  currentContragent: contragents.currentContragent
});

const mapDispatchToProps = dispatch => ({
  isAuth: bool => dispatch(isAuth(bool)),
  changeCurrentClaym: claym => dispatch(changeCurrentClaym(claym)),
  deleteCurrentClaim: id => dispatch(deleteCurrentClaim(id)),
  updateClaims: id => dispatch(updateCurrentClaims(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(ClaimsMessages);
