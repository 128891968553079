import axios from "axios";

export default class PasswordService {
  savePassword = async (body) => {
    const { data } = await axios.post("/yavir2000/api/auth/register", body);
    return data;
  };

  checkPassword = async (body) => {
    const { data } = await axios.post("/yavir2000/api/auth/login", body);
    return data;
  };

  changePasswordApi = async (body, token) => {
    const { data } = await axios.post("/yavir2000/api/auth/changepassword", body, {
      headers: {
        Authorization: token,
      },
    });
    return data;
  };
}
