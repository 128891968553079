import React, { Component } from "react";
import { connect } from "react-redux";
import MaterializeService from "../../services/materialize-sevices/materialize-js";
import PasswordCard from "../../components/password-card/password-card";
import PasswordService from "../../services/password-services/password-services";
import {
  changePassword,
  changeCheckPassword,
  fetchSettingsSuccess,
  changeEmail,
  initModalEmail
} from "../../actions";
import "./settings.css";
import ModalEmail from "../../components/modal-email/modal-email";
import EmailService from "../../services/email-service/email-service";
import SettingsText from "../../components/settings-text/settings-text";
import SettingsService from "../../services/settings-services/settingsService";
import { deleteEmail } from "../../actions/contragents-action";

const settingsService = new SettingsService();
const passwordServise = new PasswordService();
const emailService = new EmailService();
const materialize = new MaterializeService();

const { initModal, updateTextField, toastSend } = materialize;
const { checkPassword, changePasswordApi } = passwordServise;
const { fetchSettings, postNewSettings, deleteEmaill } = settingsService;
const { sendMail } = emailService;

class Settings extends Component {
  isMounting = false;
  state = {
    dbUser: "true",
    isForgotPassword: "true",
    confirmPassword: "",
    failedPassword: false,
    isDisabled: false,
    email: "",
    sendMail: null,
    isDisabledEmail: false,
    isLoadingDelete: false
  };

  password = React.createRef();
  email = React.createRef();

  componentDidMount = () => {
    if (this.props.phonenumber) {
      this.initModals();
    }
  };

  componentDidUpdate = prevProps => {
    if (prevProps.phonenumber !== this.props.phonenumber) {
      this.initModals();
    }
    if (prevProps.currentContragent !== this.props.currentContragent) {
      this.setState({ emails: this.props.currentContragent.e_mail });
    }
  };

  initModals = () => {
    const { initModalEmail } = this.props;
    this.passwordModalInstanse = initModal(this.password.current);
    initModalEmail(initModal(this.email.current));
  };

  onHandleClickChangePassword = () => {
    this.passwordModalInstanse.open();
  };

  onHandleClickChangeEmail = () => {
    const { emailModalInstanse } = this.props;
    this.setState({ sendMail: null });
    emailModalInstanse.open();
  };

  onOpenSupportModal = () => {
    this.supportModaInstanse.open();
  };

  onCloseModalInstans = () => {
    this.supportModaInstanse.close();
  };

  deleteEmail = async email => {
    const { isRoot, currentContragent, deleteEmailAction } = this.props;
    this.setState({ isLoadingDelete: true });
    const { error } = await deleteEmaill(isRoot, {
      email,
      refKey: currentContragent.Ref_Key
    });
    if (error) {
      return toastSend("Щось пішло не так, повторіть спробу!");
    }
    this.setState({ isLoadingDelete: false });
    deleteEmailAction(email);
  };

  onHandleSubmitPassword = async e => {
    e.preventDefault();
    const {
      isRoot,
      password,
      changePassword,
      changeConfirmPassword
    } = this.props;
    const { dbUser } = this.state;
    this.setState({
      isDisabled: true
    });
    if (dbUser) {
      const body = { password, isRoot };
      const { passwordRes } = await checkPassword(body);
      if (!passwordRes) {
        this.setState({
          failedPassword: "Пароль невірний",
          isDisabled: false
        });
        changePassword("");
        changeConfirmPassword("");
        return;
      }
      this.setState({
        failedPassword: null,
        dbUser: null,
        isDisabled: false
      });
      changePassword("");
      updateTextField();
      return;
    }
    const { save } = await changePasswordApi({ password }, isRoot);
    if (save) {
      toastSend("Пароль змінено!");
      this.passwordModalInstanse.close();
    } else {
      toastSend("Щось пішло не так. Спробуйте ще раз");
    }
    this.setState({
      isDisabled: false,
      dbUser: "true"
    });
    changePassword("");
    changeConfirmPassword("");
  };

  closeModal = e => {
    const { changePassword } = this.props;
    e.preventDefault();
    changePassword("");
    updateTextField();
    this.setState({ dbUser: "true", isForgotPassword: "true" });
    this.passwordModalInstanse.close();
  };

  closeEmailModal = e => {
    const { emailModalInstanse } = this.props;
    e.preventDefault();
    emailModalInstanse.close();
  };

  closeTypeInfoModal = async () => {
    const { isRoot, getSettings, currentContragent } = this.props;
    this.setState({ isLoadingSettings: true });
    const response = await fetchSettings(isRoot, { currentContragent });
    const { error } = response;
    if (error) {
      toastSend("Щось пішло не так. Спробуйте ще раз.");
      return;
    }
    getSettings(response);
    this.typeInfoModalInstanse.close();
    this.setState({ isLoadingSettings: false });
  };

  changeEmailField = e => {
    this.setState({
      email: e.target.value
    });
  };

  onHandleSubmitEmail = async e => {
    const { email } = this.state;
    const { isRoot, currentContragent } = this.props;
    e.preventDefault();
    this.setState({ isDisabledEmail: true });
    const candidate = currentContragent.e_mail.find(e => e === email);
    if (candidate) {
      this.setState({ isDisabledEmail: false });
      toastSend("Такий email вже існує");
      return;
    }
    const { info } = await sendMail(
      { email, refKey: currentContragent.Ref_Key },
      isRoot
    );
    if (info) {
      this.setState({ isDisabledEmail: false, sendMail: "send", email: "" });
    }
  };

  onHandleSubmitSettings = async () => {
    const {
      billEmail,
      billMedok,
      debtSMS,
      debtTelegram,
      isRoot,
      id_setting
    } = this.props;
    const body = {
      billEmail,
      billMedok,
      debtSMS,
      debtTelegram,
      id_setting
    };
    this.setState({ isLoadingSettings: true });
    const { update } = await postNewSettings(isRoot, body);

    if (update) {
      this.typeInfoModalInstanse.close();
      this.setState({ isLoadingSettings: false });
    }
  };

  render() {
    const {
      dbUser,
      isForgotPassword,
      password,
      confirmPassword,
      failedPassword,
      isDisabled,
      email,
      isDisabledEmail,
      sendMail,
      isLoadingDelete
    } = this.state;
    const { phonenumber, history, currentContragent } = this.props;
    return (
      <div className="settings-wrapper">
        <SettingsText
          onHandleClickChangePassword={this.onHandleClickChangePassword}
          onHandleClickChangeEmail={this.onHandleClickChangeEmail}
          onHandleClickTypeInfo={this.onHandleClickTypeInfo}
          emails={currentContragent && currentContragent.e_mail}
          history={history}
          deleteEmail={this.deleteEmail}
          isLoadingDelete={isLoadingDelete}
        />
        {phonenumber && (
          <React.Fragment>
            {" "}
            <PasswordCard
              modal={this.password}
              password={password}
              confirmPassword={confirmPassword}
              dbUser={dbUser}
              isForgotPassword={isForgotPassword}
              onhandleChangePassword={this.onhandleChangePassword}
              onHandleSubmitPassword={this.onHandleSubmitPassword}
              failedPassword={failedPassword}
              isDisabled={isDisabled}
              closeModal={this.closeModal}
              isEditPassword={true}
              isSettings
            />
            <ModalEmail
              modalRef={this.email}
              changeEmailField={this.changeEmailField}
              email={email}
              closeModal={this.closeEmailModal}
              handleSubmit={this.onHandleSubmitEmail}
              isDisabledEmail={isDisabledEmail}
              sendMail={sendMail}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  registration,
  settings,
  modalsInstances,
  contragents
}) => ({
  confirmPassword: registration.confirmPassword,
  password: registration.password,
  isRoot: registration.isRoot,
  emailProp: settings.email,
  phonenumber: settings.phonenumber,
  billEmail: settings.billEmail,
  billMedok: settings.billMedok,
  id_setting: settings.id_setting,
  debtSMS: settings.debtSMS,
  debtTelegram: settings.debtTelegram,
  emailModalInstanse: modalsInstances.emailModalInstanse,
  currentContragent: contragents.currentContragent
});
const mapDispatchToProps = dispatch => ({
  changePassword: pass => dispatch(changePassword(pass)),
  changeConfirmPassword: pass => dispatch(changeCheckPassword(pass)),
  getSettings: settings => dispatch(fetchSettingsSuccess(settings)),
  changeEmail: email => dispatch(changeEmail(email)),
  initModalEmail: modal => dispatch(initModalEmail(modal)),
  deleteEmailAction: email => dispatch(deleteEmail(email))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings);
