import React, { Component } from "react";
import MonthYearPicker from "../picker/picker";
import "./archives-card.css";
import PdfAction from "../pdf-action/pdf-action";
import ModalDatepicker from "../modal-datepicker/modal-datepicker";
import MaterializeService from "../../services/materialize-sevices/materialize-js";
import { domain } from "../../config/config";

const baseUrl = `${domain}/yavir2000/file/`;

const materialize = new MaterializeService();

const { initModal, toastSend } = materialize;

class ArchivesCard extends Component {
  state = {
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear()
  };

  datePickerRef = React.createRef();

  componentDidMount() {
    this.datePickerModalInstanse = initModal(this.datePickerRef.current);
  }

  handleChengeMonth = month => {
    this.setState({ ...this.state, month });
  };

  handleChengeYear = year => {
    this.setState({ ...this.state, year });
  };

  openModal = () => {
    this.datePickerModalInstanse.open();
  };

  confirmDate = () => {
    this.datePickerModalInstanse.close();
  };

  onHandleGetPdf = async (route, Ref_Key) => {
    const { isRoot } = this.props;
    const { year, month } = this.state;
    const token = isRoot.split(" ")[1];
    const validMonth = month.toString().length < 2 ? `0${month}` : `${month}`;
    const dateSend = `${year}-${validMonth}-01T00:00:00`;
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    if (month > currentMonth && year >= currentYear) {
      return toastSend("Обрана дата не є архівною");
    }
    if (month === currentMonth && year === currentYear) {
      const url = `${baseUrl}${route}/${Ref_Key}?param=${token}`;
      window.open(url);
      return;
    }
    const url = `${baseUrl}${route}/${Ref_Key}?date=${dateSend}&&param=${token}`;
    window.open(url);
  };

  render() {
    const { item } = this.props;
    const monthText = () => {
      switch (this.state.month) {
        case 1:
          return "cічень";
        case 2:
          return "лютий";
        case 3:
          return "березень";
        case 4:
          return "квітень";
        case 5:
          return "травень";
        case 6:
          return "червень";
        case 7:
          return "липень";
        case 8:
          return "серпень";
        case 9:
          return "вересень";
        case 10:
          return "жовтень";
        case 11:
          return "листопад";
        case 12:
          return "грудень";
        default:
          break;
      }
    };
    return (
      <div className="archives-card">
        <div className="contracts-content-archives">
          <div className="archive-contract-title">{item.number}</div>
          <div className="pdf-action">
            <PdfAction Ref_Key={item.Ref_Key} getPdf={this.onHandleGetPdf} />
          </div>
        </div>
        <div className="date-content">
          <div className="date-title">
            Обрана дата: {monthText()} {this.state.year} рік.
          </div>
          <div className="datepicker-content">
            <MonthYearPicker
              selectedMonth={this.state.month}
              selectedYear={this.state.year}
              minYear={2018}
              maxYear={new Date().getFullYear()}
              onChangeYear={year => this.handleChengeYear(year)}
              onChangeMonth={month => this.handleChengeMonth(month)}
            />
          </div>
          <button onClick={this.openModal} className="btn date-open">
            Обрати дату
          </button>
        </div>
        <ModalDatepicker
          currentRef={this.datePickerRef}
          month={this.state.month}
          year={this.state.year}
          handleChengeMonth={this.handleChengeMonth}
          handleChengeYear={this.handleChengeYear}
          confirmDate={this.confirmDate}
          monthText={monthText}
        />
      </div>
    );
  }
}

export default ArchivesCard;
