const changeTextArea = payload => {
  return {
    type: 'CHANGE_TEXT_AREA',
    payload
  }
}

const changeSelect = payload => {
  return {
    type: 'CHANGE_SELECT',
    payload
  }
}

const changeCurrentClaym = payload => {
  return {
    type: 'CHANGE_CURRENT_TYPE_CLAYM',
    payload
  }
}

const fetchCurrentClaims = payload => {
  return {
    type: 'FETCH_CURRENT_CLAIMS_LIST_SUCCESS',
    payload
  }
}

const deleteCurrentClaim = payload => {
  return {
    type: 'DELETE_CURRENT_CLAIM',
    payload
  }
}

const updateCurrentClaims = payload => {
  return {
    type: 'UPDATE_CLAIMS_LIST',
    payload
  }
}

export {
  changeTextArea,
  changeSelect,
  changeCurrentClaym,
  fetchCurrentClaims,
  deleteCurrentClaim,
  updateCurrentClaims
}
