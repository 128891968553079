import React from "react";
import "./modal-archive-bills.css";
import moment from "moment";
import { Button } from "semantic-ui-react";

const ModalArchiveBills = ({ list, refModal }) => {
  const mutationSum = () => {
    return list.map(item => {
      item.sum = +item.sum.toString().replace(",", ".");
      return item;
    });
  };

  const calculateBills = () => {
    const newList = mutationSum();
    return newList.reduce((total, item) => (total += item.sum * 100), 0) / 100;
  };

  return (
    <div className="modal archive-modal-bills" ref={refModal}>
      <table className="centered">
        <thead>
          <tr>
            <th>№</th>
            <th className="th-center">Дата</th>
            <th className="th-right">Сума</th>
          </tr>
        </thead>
        <tbody>
          {list.map((item, idx) => {
            return (
              <tr key={idx}>
                <td>{idx + 1}</td>
                <td className="table-content">
                  {moment(item.date).format("DD.MM.YYYY")}
                </td>
                <td className="table-content">{item.sum} грн.</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="total-bills">
        Загальна сума: <b>{calculateBills()} грн.</b>
      </div>
      <div className="archive-bills-button-content">
        <Button className="modal-close" content="Закрити" icon="close" />
      </div>
    </div>
  );
};

export default ModalArchiveBills;
