import React, { Component } from "react";

import "./support-actions.css";

class SupportActions extends Component {
  state = {};
  render() {
    return (
      <div className="technical-work">
        Вибачте, в даний час цей розділ знаходиться на оновленні. <br /> По всім
        питанням звертайтесь до свого інспектора.
      </div>
      // <div className="support-actions">
      //   <button
      //     className="btn support"
      //     onClick={() => history.push("/support/new")}
      //   >
      //     створити нове
      //   </button>
      //   <button
      //     className="btn support"
      //     onClick={() => history.push("/support/current")}
      //   >
      //     поточні
      //   </button>
      //   <button
      //     className="btn support"
      //     onClick={() => history.push("/support/archive")}
      //   >
      //     архів
      //   </button>
      // </div>
    );
  }
}

export default SupportActions;
