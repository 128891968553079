import React, { Component } from 'react'
import { connect } from 'react-redux'
import ClaimSelect from '../../components/claim-select/claim-select'
import './new-claim.css'
import SupportTextarea from '../../components/support-textarea/support-textarea'
import {
  changeTextArea,
  isAuth,
  changeSelect,
  changeCurrentClaym
} from '../../actions'
import SupportService from '../../services/support-service/support-service'
import MaterializeService from '../../services/materialize-sevices/materialize-js'
import Preloader from '../../components/preloader/preloader'

const supportService = new SupportService()
const materialize = new MaterializeService()

const { submitClaimApi, fetchClaymType } = supportService
const { selectInit, toastSend } = materialize

class NewClaim extends Component {
  isMounting = false
  state = {
    types: [],
    isLoading: false,
    isLoadingBtn: false
  }

  selectRef = React.createRef()

  componentDidMount () {
    this.isMounting = true
    this.fetchClaymTypeSuccess()
  }

  componentWillUnmount () {
    this.isMounting = false
  }

  fetchClaymTypeSuccess = async () => {
    try {
      const { isRoot } = this.props
      this.setState({ isLoading: true })
      const { result } = await fetchClaymType(isRoot)
      if (!this.isMounting) return
      this.setState({ types: result, isLoading: false })
      selectInit(this.selectRef.current)
    } catch (error) {
      this.ifError()
    }
  }

  onHandleChangeTextarea = e => {
    const { changeTextArea } = this.props
    changeTextArea(e.target.value)
  }

  onHandleChangeSelect = e => {
    const { changeSelect } = this.props
    changeSelect(e.target.value)
  }

  onHandleSubmitClaim = async () => {
    const {
      isRoot,
      text,
      selected,
      changeTextArea,
      phonenumber,
      history,
      changeCurrentClaym,
      currentContragent
    } = this.props
    try {
      const typeClaim = this.state.types.find(
        item => item.desc_type_claim === selected
      )
      const data = {
        text,
        typeClaim: typeClaim.type_claim,
        phonenumber,
        contragent_name: currentContragent.fullname
      }
      this.setState({ isLoadingBtn: true })
      const { insert, error } = await submitClaimApi(data, isRoot)
      if (error) return toastSend('Щось пішло не так, спробуйте ще раз')
      changeTextArea('')
      this.setState({ isLoadingBtn: false })
      changeCurrentClaym(insert)
      history.push(`/support/current/${insert}`)
      toastSend(`Заявка додана!`)
    } catch (error) {
      this.ifError()
    }
  }

  ifError = () => {
    const { isAuth, history } = this.props
    isAuth(null)
    localStorage.clear()
    history.push('/login')
  }

  render () {
    const { text, selected } = this.props
    const { types, isLoading, isLoadingBtn } = this.state
    if (isLoading) {
      return <Preloader />
    }
    return (
      <div className='new-claim-wrapper'>
        <div className='new-claim z-depth-1'>
            <ClaimSelect
              selected={selected}
              onHandleChangeSelect={this.onHandleChangeSelect}
              selectRef={this.selectRef}
              types={types}
            />
            <SupportTextarea
              text={text}
              selected={selected}
              onHandleChangeTextarea={this.onHandleChangeTextarea}
              onHandleSubmitClaim={this.onHandleSubmitClaim}
              isLoading={isLoadingBtn}
            />
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ textAreaText, registration, contragents }) => ({
  text: textAreaText.text,
  phonenumber: registration.phoneNumber,
  selected: textAreaText.selected,
  isRoot: registration.isRoot,
  currentContragent: contragents.currentContragent
})
const mapDispatchToProps = dispatch => ({
  changeTextArea: text => dispatch(changeTextArea(text)),
  changeSelect: value => dispatch(changeSelect(value)),
  isAuth: bool => dispatch(isAuth(bool)),
  changeCurrentClaym: claym => dispatch(changeCurrentClaym(claym))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewClaim)
