import axios from "axios";

export default class CheckTokenService {
  checkToken = async token => {
    const { data } = await axios.get("/yavir2000/check/control", {
      headers: {
        Authorization: token
      }
    });
    return data;
  };

  refreshToken = async token => {
    const { data } = await axios.get("/yavir2000/check/refresh", {
      headers: {
        Authorization: token
      }
    });
    return data;
  };
}
