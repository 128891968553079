import React from "react";
import "./contracts-collapsible.css";
import HeaderPreloader from "../header-preloader/header-preloader";
import { Icon } from "semantic-ui-react";

const ContractsCollapsible = ({
  list,
  current,
  refCollaps,
  onHandleSelectContragent,
  isLoading
}) => {
  const className = !current
    ? "collapsible-header"
    : "collapsible-header select-collaps";

  const contracts = list.map((item, idx) => {
    return (
      <li
        className="collection-item contracts-col"
        key={idx}
        onClick={() => onHandleSelectContragent(item)}
      >
        {item.fullname}
      </li>
    );
  });

  const text = !current ? "Оберіть контрагента" : current.fullname;
  if (isLoading) {
    return <HeaderPreloader />;
  }
  return (
    <ul className="collapsible" ref={refCollaps}>
      <li>
        <div className={className}>
          <Icon
            name="users"
            size="large"
            className="peple"
            style={{
              marginRight: "10px"
            }}
          />
          {text}
          <i className="material-icons right collaps">arrow_drop_down</i>
        </div>
        <div className="collapsible-body">
          <ul className="collection collaps">{contracts}</ul>
        </div>
      </li>
    </ul>
  );
};

export default ContractsCollapsible;
