const initialState = {
    title: 'Оберіть контрагента',
    name: null,
    debt: null,
    contractNumber: null,
}

const contractsTitle = (state = initialState, { type, payload }) => {
    switch (type) {
        case "SHOW_CONTRAGENT_TITLE":
          return {
            ...state,
            title: payload,
          };
        
          case "SHOW_CONTRACT_TITLE":
              return {
                  ...state,
                  debt: payload.debt,
                  contractNumber: payload.contractNumber
              }

        default:
          return state;
      }
}

export default contractsTitle;