const messages = {
  noNumberFound: `Цей номер не верифікований.
  Перевірте правильність введення номера або
  зверніться до свого менеджера для його верифікації.`,
  noPIN: "Ви ввели невірний PIN",
  sendSms: `Щойно на ваш мобільний номер телефону було відправлене
    SMS-повідомлення з PIN-кодом. Будь-ласка, введіть отриманий код та
    натисніть кнопку "Надіслати"!`,
  sendSmsTelegramSMS: `Щойно на ваш акаунт у Telegram було відправлене
  повідомлення з PIN-кодом. Будь-ласка, введіть отриманий код та
  натисніть кнопку "Надіслати"!`,
  noSendSms: `Вам вже було відправлене повідомлення. 
    Якщо бажаєте отримати нове повідомлення з PIN-кодом, 
    дочекайтесь завершення відліку таймеру та натисніть кнопку "Надіслати знову"!`
};

export default messages;
