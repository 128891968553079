import React from "react";
import "./text-area.css";

const TextArea = ({
  text,
  onhandleChange,
  textAreaRef,
  placeholder,
  id,
  marginTop = "0px"
}) => {
  return (
    <div className="textarea-wrapper" style={{ marginTop }}>
      <div className="input-field col s6 textarea">
        <textarea
          id={id}
          className="materialize-textarea revision"
          value={text}
          onChange={onhandleChange}
          ref={textAreaRef}
          maxLength={1000}
        />
        <label htmlFor={id}>{placeholder}</label>
        <span className="helper-text">Мінімум 6 символів</span>
      </div>
    </div>
  );
};

export default TextArea;
