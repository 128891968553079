import axios from "axios";

export default class SoneApiService {
  getUser = async (phoneNumber) => {
    const { data } = await axios.post(`/yavir2000/api/auth`, phoneNumber, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data;
  };

  getContractsApi = async (refKey, token) => {
    const { data } = await axios.post(`/yavir2000/api/contracts`, refKey, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    return data;
  };

  getObjectsApi = async (refKey, token) => {
    const { data } = await axios.post(`/yavir2000/api/objects`, refKey, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    return data;
  };

  getPinCode = async (phone) => {
    const { data } = await axios.post("/yavir2000/api/getpin", phone);
    return data;
  };

  checkPin = async (body) => {
    const { data } = await axios.post("/yavir2000/api/getpin/check", body);
    return data;
  };
}
