import React from "react";
import "./modal-email.css";
import ModalButtons from "../modal-buttons/modal-buttons";
import { Button } from "semantic-ui-react";
const reg = /^[-._a-z0-9]+@(?:[a-z0-9][-a-z0-9]+\.)+[a-z]{2,6}$/;

const ModalEmail = ({
  modalRef,
  changeEmailField,
  email,
  closeModal,
  handleSubmit,
  isDisabledEmail,
  sendMail
}) => {
  const sendClassName = sendMail && "send";
  const text = !sendMail
    ? 'Будь ласка, введіть свій email та натисніть кнопку "Надіслати"!'
    : "Вам на пошту відправлене посилання для підвердження вашої пошти, яке буде діяти наступні 2 хвилини. Будь ласка, знайдіть це посилання та перейдіть за ним";
  return (
    <div className={`modal email ${sendClassName}`} ref={modalRef}>
      <React.Fragment>
        <div className="modal-content email">
          <div className="modal-text-content">{text}</div>
        </div>
        {!sendMail && (
          <form
            className="modal-footer"
            onSubmit={handleSubmit}
            style={{ paddingBottom: "10px" }}
          >
            <div className="input-field">
              <input
                id="email"
                type="email"
                className="validate"
                onChange={changeEmailField}
                value={email}
                placeholder="Email"
                style={{ textAlign: "center" }}
              />
            </div>
            <ModalButtons
              handleCancel={closeModal}
              disabled={!email.match(reg)}
              isDisabled={isDisabledEmail}
            />
          </form>
        )}
        {sendMail && (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              marginTop: "15px",
              marginBottom: "10px"
            }}
          >
            <Button
              className="modal-close-once sender"
              onClick={closeModal}
              content="Закрити"
              icon="close"
            />
          </div>
        )}
      </React.Fragment>
      <button
        className="modal-close btn waves-effect waves-light modal-close-mobile"
        style={{ background: "white" }}
      >
        <i className="material-icons center">close</i>
      </button>
    </div>
  );
};

export default ModalEmail;
