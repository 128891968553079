import React, { Component } from "react";
import {
  isAuth,
  changeCurrentClaym,
  fetchCurrentClaims,
  updateCurrentClaims
} from "../../actions";
import { connect } from "react-redux";
import SupportService from "../../services/support-service/support-service";
import MaterializeService from "../../services/materialize-sevices/materialize-js";
import Preloader from "../../components/preloader/preloader";
import "./claims.css";
import ClaimsList from "../../components/claims-list/claims-list";

const materialize = new MaterializeService();

const supportService = new SupportService();

const { getCurrentClaimApi } = supportService;
const { toastSend, selectInit } = materialize;

class Claims extends Component {
  state = {
    isLoading: false,
    archiveSelectClaim: ""
  };

  claimRef = React.createRef();

  componentDidMount() {
    if (this.props.currentContragent) {
      this.fetchcurrentClaims();
    }
    this.socketChange();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.currentContragent && this.props.currentContragent) {
      this.fetchcurrentClaims();
    }
    if (
      prevProps.currentContragent &&
      prevProps.currentContragent.Ref_Key !==
        this.props.currentContragent.Ref_Key
    ) {
      if (this.props.type === "current") {
        this.props.history.push("/support/current");
      } else {
        this.props.history.push("/support/archive");
      }
      this.fetchcurrentClaims();
    }
  }

  componentWillUnmount() {
    if (this.props.type === "current") {
      this.props.socket.removeListener("updateClaim");
      this.props.socket.removeListener("apdateAfterRevision");
    }
  }

  socketChange = () => {
    const { socket, updateCurrentClaims, type } = this.props;
    if (type === "current") {
      socket.on("updateClaim", ({ id, type }) => {
        updateCurrentClaims({ id: +id, type });
      });
      socket.on("apdateAfterRevision", id => {
        updateCurrentClaims({ id: +id, type: 0 });
      });
    }
  };

  fetchcurrentClaims = async () => {
    try {
      const {
        phoneNumber,
        isRoot,
        fetchCurrentClaims,
        type,
        currentContragent
      } = this.props;
      this.setState({ isLoading: true });
      const { result, error } = await getCurrentClaimApi(
        { phoneNumber, type, contragent_name: currentContragent.fullname },
        isRoot
      );
      if (error) {
        toastSend("Щось пішло не так, спробуйте ще раз");
        return;
      }
      const currentSort = (a, b) => {
        return -a.id_claim + b.id_claim;
      };

      const archiveSort = (a, b) => {
        return -a.closed + b.closed;
      };
      type === "current" ? result.sort(currentSort) : result.sort(archiveSort);
      fetchCurrentClaims(result);
      selectInit(this.claimRef.current);
      this.setState({ isLoading: false });
    } catch (error) {
      this.props.history.push("/login");
      localStorage.clear();
      this.props.isAuth(null);
    }
  };

  onHandleChangeClaimList = item => {
    const { history, changeCurrentClaym, type } = this.props;
    switch (type) {
      case "current":
        changeCurrentClaym(+item.id_claim);
        history.push(`/support/current/${item.id_claim}`);
        break;
      case "archive":
        this.setState({
          archiveSelectClaim: +item.id_claim
        });
        history.push(`/support/archive/${item.id_claim}`);
        break;
      default:
        break;
    }
  };

  render() {
    const { isLoading } = this.state;
    const { currentClaimsList, type, currentsClaimProp } = this.props;
    const text =
      type === "current" ? "Немає поточних заявок" : "Немає архівів заявок";
    if (isLoading) {
      return <Preloader />;
    }
    return (
      <div className="current-clayms-wrapper">
        <div className="current-clayms-content z-depth-1">
          {currentClaimsList.length === 0 ? (
            <div className="no-current-claim">{text}</div>
          ) : (
            <ClaimsList
              type={type}
              list={currentClaimsList}
              onHandleChangeClaimList={this.onHandleChangeClaimList}
              selectClaim={currentsClaimProp}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ registration, textAreaText, contragents }) => ({
  isRoot: registration.isRoot,
  phoneNumber: registration.phoneNumber,
  currentsClaimProp: textAreaText.currentTypeClaim,
  currentClaimsList: textAreaText.currentClaimsList,
  currentContragent: contragents.currentContragent
});

const mapDispatchToProps = dispatch => ({
  isAuth: bool => dispatch(isAuth(bool)),
  changeCurrentClaym: claym => dispatch(changeCurrentClaym(claym)),
  fetchCurrentClaims: claims => dispatch(fetchCurrentClaims(claims)),
  updateCurrentClaims: data => dispatch(updateCurrentClaims(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Claims);
