import React from "react";
import "./modal-registration.css";
import messages from "../../services/messages/messages";
import ModalButtons from "../modal-buttons/modal-buttons";

const { noSendSms, sendSms, sendSmsTelegramSMS } = messages;

const ModalRegistration = ({
  modalRegistration,
  onCloseModal,
  onHandleSubmit,
  value,
  onInputChange,
  isDisabled,
  failedPin,
  sendTimer,
  replaySend,
  timeToReplay,
  typeSend,
  focusInputPIN
}) => {
  const message = () => {
    if (timeToReplay >= 120) {
      if (typeSend === "SMS") {
        return sendSms;
      }
      if (typeSend === "telegram") {
        return sendSmsTelegramSMS;
      }
    } else {
      return noSendSms;
    }
  };

  return (
    <div className="modal registration-block" ref={modalRegistration}>
      <React.Fragment>
        <div
          className="modal-content registration-modal"
          style={{ textAlign: "center" }}
        >
          <h4>Шановний клієнте!</h4>
          <p style={{ textAlign: "center" }} className="password-p">
            {message()}{" "}
          </p>
        </div>
        <form className="modal-footer">
          <div className="input-field">
            <input
              value={value}
              id="pin"
              type="text"
              className="validate"
              onChange={onInputChange}
              ref={focusInputPIN}
              placeholder="Введіть отриманий PIN:"
            />
            {failedPin && (
              <span
                className="helper-text red-text"
                style={{ width: "100%", textAlign: "left" }}
              >
                {failedPin}
              </span>
            )}
            {!sendTimer ? (
              <span
                onClick={replaySend}
                className="helper-text blue-text replay-send"
                style={{ width: "100%", textAlign: "left" }}
              >
                Надіслати знову?
              </span>
            ) : (
              <React.Fragment>
                <span
                  className="helper-text black-text timer-send"
                  style={{ width: "100%", textAlign: "left" }}
                >
                  Повідомлення відправлене.
                </span>
                <span
                  className="helper-text black-text timer-send"
                  style={{ width: "100%", textAlign: "left" }}
                >
                  Наступне можно отримати через {sendTimer} сек.
                </span>
              </React.Fragment>
            )}
          </div>
          <ModalButtons
            handleCancel={onCloseModal}
            handleSubmit={onHandleSubmit}
            isDisabled={isDisabled}
            disabled={value.length < 4}
          />
        </form>
      </React.Fragment>

      <button
        onClick={onCloseModal}
        className="btn waves-effect waves-light modal-close-mobile"
      >
        <i className="material-icons center">close</i>
      </button>
    </div>
  );
};

export default ModalRegistration;
