const getContractsSuccess = payload => {
  return {
    type: 'GET_CONTRACTS_SUCCESS',
    payload
  }
}

const selectContracts = payload => {
  return {
    type: 'SELECT_CONTRACTS',
    payload
  }
}


export {
  getContractsSuccess,
  selectContracts,
}
