import React, { Component } from "react";
import "./liqpay.css";
import {
  createSignature,
  redirectToLiqpay
} from "../../services/liqpay-api-service/ligpay-api";
import MaterializeService from "../../services/materialize-sevices/materialize-js";
import { Segment, Button } from "semantic-ui-react";

const { toastSend } = new MaterializeService();

class LiqPay extends Component {
  state = {
    data: "",
    signature: "",
    amount: "",
    isLoading: false,
    disabled: false,
    preloaderClassname: "",
    isSuccess: false
  };

  inputRef = React.createRef();

  componentDidMount() {
    this.inputRef && this.inputRef.current.focus();
  }

  handleChangeAmount = e => {
    this.setState({ amount: e.target.value });
  };

  handleSubmitAmount = async e => {
    e.preventDefault();
    const {
      Ref_Key,
      token,
      history,
      isAuth,
      phonenumber,
      Organization_Ref_Key,
      contragent,
      number
    } = this.props;
    this.setState({ isLoading: true });
    const { data, signature, error, status, msg } = await createSignature(
      {
        Ref_Key,
        Organization_Ref_Key,
        contragentKey: contragent.Ref_Key,
        contragentName: contragent.fullname,
        number,
        amount: (this.state.amount * 1.028278).toFixed(2)
      },
      token,
      phonenumber
    );
    if (error && !status && msg === "incorrect Type") {
      return this.errorHandler(false);
    }
    if (error && !status) {
      this.errorHandler(true, "Сума має бути більшою за 0!");
      return;
    }
    if (error && status && status === 401) {
      return this.logout(history, isAuth);
    }

    if (error && status && status === 404) {
      this.errorHandler(
        true,
        "Для даної угоди поки що немає можливості здійснювати проплату"
      );
      return;
    }
    this.endChange(data, signature);
  };

  logout = (history, isAuth) => {
    isAuth(null);
    localStorage.clear();
    history.push("/login");
  };

  errorHandler = (isToast, text) => {
    this.setState({
      isLoading: false,
      preloaderClassname: "",
      data: "",
      signature: ""
    });
    isToast && toastSend(text);
  };

  endChange = (data, signature) => {
    this.setState({
      data,
      signature,
      isLoading: false,
      disabled: false,
      isSuccess: true
    });
    this.props.setIsSuccess(true);
    this.props.setSum(this.state.amount);
  };

  render() {
    const { data, signature } = this.state;
    return (
      <div className="liqpay-wrapper">
        {!this.state.isSuccess && (
          <form
            onSubmit={this.handleSubmitAmount}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <div className="input-field liqpay">
              <input
                id="pay"
                ref={this.inputRef}
                type="number"
                className="validate"
                placeholder="Введіть суму в грн"
                value={this.state.amount}
                onChange={this.handleChangeAmount}
                style={{ height: "2.5rem", fontSize: "1.2rem" }}
              />
            </div>

            <Segment
              style={{
                boxShadow: "none",
                border: "none",
                width: "100%",
                padding: "10px, 0"
              }}
              className="buttons-payments-modal"
              loading={this.state.isLoading}
              disabled={this.state.disabled}
            >
              <Button
                as="div"
                className="button-liqpay back"
                style={{ margin: "0 10px" }}
                content="назад"
                icon="arrow alternate circle left"
                basic
                color="red"
                disabled={this.state.isLoading || this.state.disabled}
                onClick={() => this.props.handleClickType(null)}
              />
              <Button
                icon="arrow alternate circle right"
                basic
                disabled={
                  this.state.isLoading ||
                  this.state.disabled ||
                  !this.state.amount ||
                  this.state.amount <= 0
                }
                className="button-liqpay btn-lng"
                primary
                style={{
                  margin: "0 10px"
                }}
                content="сплатити"
                width="25%"
              />
            </Segment>
          </form>
        )}
        {this.state.isSuccess && (
          <form
            method="POST"
            action="https://www.liqpay.ua/api/3/checkout"
            acceptCharset="utf-8"
            className="liqpay-submit"
          >
            <input type="hidden" name="data" value={data} />
            <input type="hidden" name="signature" value={signature} />
            <div style={{ marginBottom: "10px" }} className="buttons-payments">
              <Button
                as="div"
                className="button-liqpay back btn-lng"
                basic
                style={{ margin: "0 10px" }}
                content="відміна"
                icon="cancel"
                color="red"
                onClick={() => {
                  this.props.handleClickType(null);
                  this.props.setIsSuccess(false);
                  this.props.setSum(null);
                }}
              />
              <Button
                className="button-liqpay btn-lng"
                basic
                primary
                style={{
                  margin: "0 10px"
                }}
                icon="check circle outline"
                content="підтвердити"
                width="25%"
                onClick={() => redirectToLiqpay(this.props.token)}
              />
            </div>
          </form>
        )}
      </div>
    );
  }
}

export default LiqPay;
