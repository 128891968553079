import React, { Component } from "react";
import { connect } from "react-redux";
import {
  selectContragent,
  getContractsSuccess,
  selectContracts,
  getObjectsSuccess,
  showContragentTitle,
  showContractTitle,
  isAuth
} from "../../actions";

import "./contracts.css";
import ContractsCard from "../../components/contacts-card/contracts-card";

class Contracts extends Component {
  render() {
    const {
      contractsList,
      objectsList,
      isRoot,
      getObjects,
      appWrapper,
      isAuth,
      history
    } = this.props;

    return (
      <div className="contracts-wrapper">
        {contractsList &&
          contractsList.map((item, idx) => {
            return (
              <ContractsCard
                key={idx}
                {...item}
                contractsList={contractsList}
                objectsList={objectsList}
                isRoot={isRoot}
                getObjects={getObjects}
                appWrapper={appWrapper}
                isAuth={isAuth}
                history={history}
              />
            );
          })}
      </div>
    );
  }
}

const mapStateToProps = ({ contragents, registration, contractsTitle }) => ({
  contragentsList: contragents.contragentsList,
  contractsList: contragents.contractsList,
  objectsList: contragents.objectsList,
  currentContragent: contragents.currentContragent,
  currentContract: contragents.currentContract,
  isRoot: registration.isRoot,
  title: contractsTitle.title,
  name: contractsTitle.name,
  debt: contractsTitle.debt,
  contractNumber: contractsTitle.contractNumber
});
const mapDispatchToProps = dispatch => ({
  selectContragent: item => dispatch(selectContragent(item)),
  getContracts: list => dispatch(getContractsSuccess(list)),
  selectContract: item => dispatch(selectContracts(item)),
  getObjects: list => dispatch(getObjectsSuccess(list)),
  showContragentTitle: text => dispatch(showContragentTitle(text)),
  showContractTitle: text => dispatch(showContractTitle(text)),
  isAuth: bool => dispatch(isAuth(bool))
});

export default connect(mapStateToProps, mapDispatchToProps)(Contracts);
