import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import './claims-list.css'

const ClaimsList = ({
  list,
  onHandleChangeClaimList,
  selectClaim,
  type
}) => {
  const listMap = () => {
    return list.map((item, idx) => {
      const textCurrent = `${idx + 1}. Заявка №${item.id_claim}, ${
        item.desc_type_claim
      }. Від ${moment(item.date_create).format('DD.MM.YYYY, H:mm:ss')}. ${
        item.online_status === 2 ? 'Розглянута.' : ''
      }`
      const textArchive = `Заявка №${item.id_claim}, ${
        item.desc_type_claim
      }. Закрита: ${moment(item.date_close).format('DD.MM.YYYY, H:mm:ss')}`

      const text = type === 'current' ? textCurrent : textArchive
      const className =
        +selectClaim === +item.id_claim
          ? 'collection-item active'
          : 'collection-item'

      return (
        <Link
          to='#'
          className={className}
          key={idx}
          onClick={() => onHandleChangeClaimList(item)}
        >
          {text}
        </Link>
      )
    })
  }
  return (
    <div className='collection-claim-list-wrapper'>
      <div className='text-claim-list'>
        {type === 'current' ? 'Поточні заявки' : 'Архів заявок'}
      </div>
      <div className='collection current-claims'>{listMap()}</div>
    </div>
  )
}

export default ClaimsList
