import React from "react";
import "./modal-delete-telegram.css";
import { Segment, Button } from "semantic-ui-react";
const ModalDeleteTelegram = ({
  modalDelegramDelete,
  closeModal,
  submitDelete,
  isLoading
}) => {
  return (
    <div className="modal telegram-delete" ref={modalDelegramDelete}>
      <div className="delete-telegram-header">
        Ви дійсно впевнені, що бажаєте видалити свій акаунт із нашого
        telegram-додадку? Після видалення, Вам доведеться знову пройти процедуру
        реєстрації, щоб отримувати його послуги.
      </div>
      <Segment
        style={{
          display: "flex",
          boxShadow: "none",
          border: "none",
          width: "100%",
          justifyContent: "center",
          marginTop: "0"
        }}
        loading={isLoading}
        disabled={isLoading}
      >
        <Button
          icon="check"
          disabled={isLoading}
          loading={isLoading}
          className="button-liqpay"
          style={{
            margin: "0 10px",
            background: "#26a69a",
            color: "white"
          }}
          content="так"
          onClick={submitDelete}
        />
        <Button
          className="button-liqpay back"
          style={{ margin: "0 10px" }}
          content="ні"
          icon="cancel"
          color="red"
          disabled={isLoading}
          onClick={closeModal}
        />
      </Segment>
    </div>
  );
};

export default ModalDeleteTelegram;
