import React, { Component } from "react";
import "./modal-type-info.css";
import { connect } from "react-redux";
import { fetchSettingsSuccess } from "../../actions/settings-action";
import ModalButtons from "../modal-buttons/modal-buttons";

class ModalTypeInfo extends Component {
  onCheckedTypeBill = type => {
    const { handleChangeContragentSettings } = this.props;
    switch (type) {
      case "email":
        handleChangeContragentSettings("email");
        break;
      case "medok":
        handleChangeContragentSettings("medok");
        break;
      case "telegram":
        handleChangeContragentSettings("telegram");
        break;
      default:
        break;
    }
  };

  render() {
    const {
      modalRef,
      billMedok,
      billEmail,
      debtTelegram,
      isModalLoading,
      onSubmitContractsSettings,
      isTelegramAkk,
      closeTypeInfo,
      refKey,
      currentContragent,
      history
    } = this.props;

    return (
      <div className="modal type-info" ref={modalRef}>
        <React.Fragment>
          <div className="modal-content type-info">
            <h4>Шановний клієнте</h4>
            <p>Оберіть способи вашого інформування</p>
          </div>
          <div className="modal-footer type-info">
            <div className="text-content">
              <div className="checkbox-wrapper">
                <h6 className="text-bills">Спосіб отримання рахунків:</h6>
                <div className="checkbox">
                  {currentContragent &&
                  currentContragent.e_mail.length === 0 ? (
                    <label>
                      <input
                        disabled={
                          currentContragent &&
                          currentContragent.e_mail.length === 0
                        }
                        type="checkbox"
                        checked={false}
                      />
                      <span
                        onClick={() => {
                          history.push("/settings");
                        }}
                      >
                        Email
                      </span>
                    </label>
                  ) : (
                    <label>
                      <input
                        type="checkbox"
                        checked={billEmail}
                        onChange={() => this.onCheckedTypeBill("email")}
                      />
                      <span>Email</span>
                    </label>
                  )}
                </div>
                <div className="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      checked={billMedok}
                      onChange={() => this.onCheckedTypeBill("medok")}
                    />
                    <span>МЕДОК</span>
                  </label>
                </div>
              </div>
              <div className="checkbox-wrapper">
                <h6 className="text-bills">
                  Спосіб інформування про заборгованість:
                </h6>
                <div className="checkbox">
                  <label>
                    <input type="checkbox" defaultChecked disabled />
                    <span>SMS</span>
                  </label>
                </div>
                {!isTelegramAkk ? (
                  <div className="checkbox">
                    <label
                      ref={this.tooltipTelegram}
                      className="tooltipped"
                      data-position="right"
                      data-tooltip="Зареєструйтесь у Telegram"
                    >
                      <input
                        type="checkbox"
                        disabled={!isTelegramAkk}
                        checked={debtTelegram}
                      />
                      <span>Telegram</span>
                    </label>
                  </div>
                ) : (
                  <div className="checkbox">
                    <label>
                      <input
                        type="checkbox"
                        disabled={!isTelegramAkk}
                        checked={debtTelegram}
                        onChange={() => this.onCheckedTypeBill("telegram")}
                      />
                      <span>Telegram</span>
                    </label>
                  </div>
                )}
              </div>
            </div>
            <ModalButtons
              handleCancel={() => closeTypeInfo({ refKey })}
              handleSubmit={() => onSubmitContractsSettings(refKey)}
              isDisabled={isModalLoading}
              isSettings={true}
            />
          </div>
        </React.Fragment>

        <button
          onClick={() => closeTypeInfo({ refKey })}
          className="btn waves-effect waves-light modal-close-mobile"
        >
          <i className="material-icons center">close</i>
        </button>
      </div>
    );
  }
}

const mapStateToProps = ({ settings, registration, contragents }) => ({
  isRoot: registration.isRoot,
  email: settings.email,
  phonenumber: settings.phonenumber,
  debtSMS: settings.debtSMS,
  isTelegramAkk: settings.isTelegramAkk,
  currentContragent: contragents.currentContragent
});
const mapDispatchToProps = dispatch => ({
  getSettings: settings => dispatch(fetchSettingsSuccess(settings))
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalTypeInfo);
