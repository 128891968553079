import React from "react";
import "./preloader.css";

const Preloader = () => (
  <div className="row">
    <div className="col s12 center">
      <div
        className="preloader-wrapper big active center"
        style={{ marginTop: "25px" }}
      >
        <div className="spinner-layer spinner-blue-only">
          <div className="circle-clipper left">
            <div className="circle"></div>
          </div>
          <div className="gap-patch">
            <div className="circle"></div>
          </div>
          <div className="circle-clipper right">
            <div className="circle"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Preloader;
