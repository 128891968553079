const initialState = {
  contragentsList: [],
  objectsList: null,
  contractsList: null,
  currentContract: null,
  currentContragent: null
}

const contragents = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'GET_CONTRAGENTS_SUCCESS':
      return {
        ...state,
        contragentsList: payload
      }

    case 'GET_CONTRACTS_SUCCESS':
      return {
        ...state,
        contractsList: payload
      }

    case 'SELECT_CONTRAGENT':
      return {
        ...state,
        currentContragent: payload
      }

    case 'SELECT_CONTRACTS':
      return {
        ...state,
        currentContract: payload
      }

    case 'GET_OBJECTS_SUCCESS':
      return {
        ...state,
        objectsList: payload
      }

    case 'DELETE_EMAIL':
      const newCurrent = { ...state.currentContragent }
      newCurrent.e_mail = newCurrent.e_mail.filter(e => e !== payload)
      const newContragentsList = [...state.contragentsList]
      const contragentIdx = newContragentsList.findIndex(
        c => c.Ref_Key === state.currentContragent.Ref_Key
      )
      newContragentsList[contragentIdx].e_mail = newCurrent.e_mail
      return {
        ...state,
        contragentsList: newContragentsList,
        currentContragent: newCurrent
      }

    case 'ADD_EMAIL':
      const newContragentsListToAdd = [...state.contragentsList]
      const idxToAdd = newContragentsListToAdd.findIndex(
        c => c.Ref_Key === payload.refKey
      )
      const candidateEmail = newContragentsListToAdd[idxToAdd].e_mail.find(e => e === payload.email)
      if (candidateEmail) {
        return {
          ...state
        }
      }
      const currentToAdd = { ...state.currentContragent }
      if (currentToAdd.Ref_Key === payload.Ref_Key) {
        currentToAdd.e_mail.push(payload.email)
      }
      newContragentsListToAdd[idxToAdd].e_mail.push(payload.email)
      return {
        ...state,
        contragentsList: newContragentsListToAdd,
        currentContragent: currentToAdd
      }
    default:
      return state
  }
}

export default contragents
