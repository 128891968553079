import React from "react";
import MonthYearPicker from "../../components/picker/picker";
import "./modal-datepicker.css";
import { Button } from "semantic-ui-react";
const ModalDatepicker = ({
  month,
  year,
  currentRef,
  handleChengeYear,
  handleChengeMonth,
  confirmDate,
  monthText
}) => {
  return (
    <div className="modal modal-date" ref={currentRef}>
      <div className="modal-content-date">
        <div className="date-title">
          {" "}
          Обрана дата: {monthText()} {year} рік
        </div>
        <MonthYearPicker
          selectedMonth={month}
          selectedYear={year}
          minYear={2018}
          maxYear={new Date().getFullYear()}
          onChangeYear={year => handleChengeYear(year)}
          onChangeMonth={month => handleChengeMonth(month)}
        />
        <Button
          as="div"
          onClick={confirmDate}
          className="btn confirm-date"
          content="Підтвердити"
          icon="check"
        />
      </div>
    </div>
  );
};

export default ModalDatepicker;
