import React from "react";
import "./sidenav.css";
import { Menu } from "semantic-ui-react";

const yavirLogo = require("../../assets/logo.png");

const SideNav = ({ sidenavRef, logOut, history, closeSidenav }) => {
  return (
    <div
      className="sidenav mobile-size"
      ref={sidenavRef}
      style={{ padding: "0" }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "150px",
          backgroundColor: "#88e7c8"
        }}
      >
        <div className="logo-sidenav">
          <img src={yavirLogo} alt="yavir" />
        </div>
      </div>
      {/* <div className="header-navigation">
        <button
          onClick={() => {
            history.push("/contracts");
            closeSidenav();
          }}
          className="btn navigation mobile"
        >
          Угоди
        </button>
        <button
          onClick={() => {
            history.push("/archives");
            closeSidenav();
          }}
          className="btn navigation mobile"
        >
          Архіви
        </button>
        <button
          onClick={() => {
            history.push("/settings");
            closeSidenav();
          }}
          className="btn navigation mobile"
        >
          Налаштування
        </button>
        <button
          className="btn navigation mobile"
          onClick={() => {
            closeSidenav();
            logOut();
          }}
        >
          Вийти
        </button>
      </div> */}
      <Menu secondary vertical>
        <Menu.Item
          icon="clipboard list"
          name="Угоди"
          active={history.location.pathname === "/contracts"}
          onClick={() => {
            history.push("/contracts");
            closeSidenav();
          }}
        />
        <Menu.Item
          name="Архіви"
          active={history.location.pathname.startsWith("/archives")}
          onClick={() => {
            history.push("/archives/bills");
            closeSidenav();
          }}
          icon="archive"
        />
        <Menu.Item
          name="Налаштування"
          icon="setting"
          active={history.location.pathname === "/settings"}
          onClick={() => {
            history.push("/settings");
            closeSidenav();
          }}
        />
        <Menu.Item
          icon="sign out alternate"
          name="Вийти"
          onClick={() => {
            closeSidenav();
            logOut();
          }}
        />
      </Menu>
    </div>
  );
};

export default SideNav;
