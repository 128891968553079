const fetchSettingsSuccess = payload => {
  return {
    type: 'FETCH_SETTINGS_SUCCESS',
    payload
  }
}

const changeEmail = payload => {
  return {
    type: 'CHANGE_EMAIL',
    payload
  }
}

const deleteTelegramAkk = () => {
  return {
    type: 'DELETE_TELEGRAM_AKK'
  }
}

export {
  fetchSettingsSuccess,
  changeEmail,
  deleteTelegramAkk
}
