import React from "react";
import "./about-us.css";

const AboutUs = ({ modalAboutUs, closeModal, organizations }) => {
  return (
    <div className="modal about-us" ref={modalAboutUs} style={{ padding: 0 }}>
      <div
        className="modal-content about-us"
        style={{
          overflowY: "hidden",
          paddingTop: "35px"
        }}
      >
        <div className="about-us-title" style={{ paddingBottom: "15px" }}>
          Групи компаній "Явір-2000"
        </div>
        <div
          style={{
            width: "100%",
            height: "45vh",
            overflowY: "auto",
            padding: "10px",
            paddingTop: "0"
          }}
        >
          {organizations.map((o, idx) => {
            return (
              <React.Fragment key={idx}>
                {o.position_agreement}. {o.text_agreement}
                <br />
                <br />
              </React.Fragment>
            );
          })}
        </div>
      </div>
      <button
        onClick={closeModal}
        className="btn waves-effect waves-light modal-close-mobile modal-close about-us-close"
      >
        <i className="material-icons center">close</i>
      </button>
    </div>
  );
};

export default AboutUs;
