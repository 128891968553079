import React from "react";
import ObjectsList from "../objects-list/objects-list";
import "./object-modal.css";
import { Button } from "semantic-ui-react";

const ObjectsModal = ({ modalObject, list, closeObjectModal, contractNumber }) => {
  return (
    <div className="modal objects-modal" ref={modalObject}>
      <div className="object-modal-title">Об'єкти по угоді {contractNumber}</div>
      <ObjectsList list={list} contractNumber={contractNumber} />
      <Button
        content="закрити"
        icon="close"
        onClick={closeObjectModal}
        className="close-object-btn"
      />
      <button
        onClick={closeObjectModal}
        className="btn waves-effect waves-light modal-close-mobile"
      >
        <i className="material-icons center">close</i>
      </button>
    </div>
  );
};

export default ObjectsModal;
