import React, { Component } from "react";
import "./qr.css";
import { Segment, Button } from "semantic-ui-react";
import QRContent from "../../components/qr-content/qr-content";

class Qr extends Component {
  state = {
    amount: "",
    submitted: "",
  };

  handleChangeAmount = (event) => {
    this.setState({ amount: event.target.value });
  };

  handleSubmitAmount = (event) => {
    event.preventDefault();
    const { amount } = this.state;
    this.setState({ submitted: true });
  };

  render() {
    const { amount, submitted } = this.state;
    const { contractNumber, iban, refKey, currentContragent, edrpou, orgName, handleClickType } =
      this.props;

    return (
      <div className="modal-content payments" style={{ display: "flex", justifyContent: "center" }}>
        {!submitted ? (
          <div className="liqpay-wrapper">
            <div style={{ marginBottom: "10px" }}>Введіть суму:</div>
            <form
              onSubmit={this.handleSubmitAmount}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className="input-field liqpay">
                <input
                  id="qr-pay"
                  type="number"
                  className="validate"
                  placeholder="Введіть суму в грн"
                  value={amount}
                  onChange={this.handleChangeAmount}
                  style={{ height: "2.5rem", fontSize: "1.2rem" }}
                />
              </div>

              <Segment
                style={{
                  boxShadow: "none",
                  border: "none",
                  width: "100%",
                  padding: "10px, 0",
                }}
                className="buttons-payments-modal"
              >
                <Button
                  as="div"
                  className="button-liqpay back"
                  content="Назад"
                  icon="arrow alternate circle left"
                  basic
                  color="red"
                  onClick={() => this.props.handleClickType(null)}
                />
                <Button
                  type="submit"
                  icon="arrow alternate circle right"
                  basic
                  className="button-liqpay btn-lng"
                  primary
                  content="Сплатити"
                  width="25%"
                  disabled={!(this.state.amount && this.state.amount > 0)}
                />
              </Segment>
            </form>
          </div>
        ) : (
          <QRContent
            iban={iban}
            refKey={refKey}
            contractNumber={contractNumber}
            currentContragent={currentContragent}
            edrpou={edrpou}
            orgName={orgName}
            amount={amount}
          />
        )}
      </div>
    );
  }
}

export default Qr;
