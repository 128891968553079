import React, { useState } from "react";
import MaterializeService from "../../services/materialize-sevices/materialize-js";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "./requisites.css";
import { Icon } from "semantic-ui-react";
const materialize = new MaterializeService();

const Requisites = ({
  item,
  textTitle,
  isDescription = false,
  isFirm = false
}) => {
  const [icon, setIcon] = useState("copy outline");
  const description = () => {
    if (isDescription) {
      return `P24. ${item.contragentName}. За послуги охорони по угоді ${item.contractNumber}. Key: ${item.refKey}`;
    }
  };

  const copyToBuffer = () => {
    if (isDescription) {
      materialize.toastSend(`Призначення платежу скопійовано`);
    } else if (isFirm) {
      materialize.toastSend(`Назва одержувача скопійована`);
    } else {
      materialize.toastSend(`${textTitle} скопійовано`);
    }
  };
  return (
    <div className="requisites-block">
      <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
        <div style={{ width: "95%" }}>
          <div className="title-description">{textTitle}</div>
          <div className="copy-to-buffer">
            {!isDescription ? (
              <input
                className="input"
                type="text"
                disabled={true}
                value={item}
              />
            ) : (
              <textarea
                className="textarea"
                rows={3}
                disabled={true}
                value={description()}
              />
            )}
          </div>
        </div>
        <CopyToClipboard
          text={isDescription ? description() : item}
          onCopy={copyToBuffer}
        >
          <Icon
            name={icon}
            size="large"
            className="icon-copy"
            onMouseOver={() => setIcon("copy")}
            onMouseOut={() => setIcon("copy outline")}
          />
        </CopyToClipboard>
      </div>
    </div>
  );
};

export default Requisites;
