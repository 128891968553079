export function isAuth(payload) {
    return {
        type: 'IS_AUTH',
        payload
    }
}

const changePassword = (payload) => {
    return {
        type: 'PASSWORD_CHANGE',
        payload
    }
}

const changeCheckPassword = (payload) => {
    return {
        type: 'CHECK_PASSWORD_CHANGE',
        payload
    }
}

export {
    changePassword,
    changeCheckPassword
}